import { REQUEST_STATUS } from "gsi-ui-components";
import {
  RESET_PASSWORD,
  SELECT_USER,
  USER_ACTION_RELATED,
  KEYCLOAK_USER_ROLES_ACTION
} from "../helpers/types";

const initialState = {
  status: null,
  resetPasswordStatus: null,
  errorMessage: null,
  isEditing: false,
  selectedUser: {},
  selectedUserId: null,
  userRoles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTION_RELATED.LOADING:
      return { ...state, status: REQUEST_STATUS.LOADING };
    case USER_ACTION_RELATED.SUCCESS:
      return { ...state, status: REQUEST_STATUS.SUCCESS };
    case USER_ACTION_RELATED.ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.ERROR,
        errorMessage: action.payload
      };
    case USER_ACTION_RELATED.CLEAR:
      return { ...state, status: initialState.status };
    case USER_ACTION_RELATED.RESET:
      return { ...initialState, userRoles: state.userRoles };
    case RESET_PASSWORD.LOADING:
      return { ...state, resetPasswordStatus: REQUEST_STATUS.LOADING };
    case RESET_PASSWORD.SUCCESS:
      return { ...state, resetPasswordStatus: REQUEST_STATUS.SUCCESS };
    case RESET_PASSWORD.ERROR:
      return {
        ...state,
        resetPasswordStatus: REQUEST_STATUS.ERROR,
        errorMessage: action.payload
      };
    case RESET_PASSWORD.CLEAR:
      return { ...state, resetPasswordStatus: initialState.status };
    case SELECT_USER:
      return { ...state, ...action.payload };
    case KEYCLOAK_USER_ROLES_ACTION:
      return { ...state, userRoles: action.payload };
    default:
      return state;
  }
};
