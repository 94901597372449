import React from "react";
import "./NotFound.less";

const NotFound = () => {
  return (
    <div className="container notfound">
      <h1>Page not found</h1>
    </div>
  );
};

export default React.memo(NotFound);
