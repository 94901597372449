import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { capitalize } from "lodash";
import {
  filterByText,
  sortFields,
  BO_LOCATION_PATH,
  REQUEST_STATUS,
  SORT_DIRECTIONS,
  UNKNOWN,
  USER_ROLES,
  USER_STATUS
} from "gsi-ui-components";
import { listUsers, selectUser } from "../../actions/user";
import InputSearch from "../common/InputSearch";
import GenericTable from "../common/genericTable/GenericTable";
import "./UsersList.less";

const parseDataSource = source => {
  return source.map(entry => {
    return {
      firstName: entry.firstName || UNKNOWN,
      lastName: entry.lastName || UNKNOWN,
      username: entry.username,
      role: capitalize(USER_ROLES.SURGEON),
      email: entry.email || UNKNOWN,
      enabled: entry.enabled,
      id: entry.id,
      key: entry.id
    };
  });
};

const inputSearch = filter => (
  <InputSearch
    setSelectedKeys={filter.setSelectedKeys}
    selectedKeys={filter.selectedKeys}
    confirm={filter.confirm}
    clearFilters={filter.clearFilters}
    placeholder={"User"}
  />
);

const actionButtons = () => {
  return (
    <Tooltip title="Edit">
      <EditOutlined />
    </Tooltip>
  );
};

const iconFiltered = filtered => (
  <SearchOutlined className={filtered ? "filtered-column" : ""} />
);

const UsersList = ({
  listUsers,
  userListData,
  status,
  history,
  selectUser
}) => {
  useEffect(() => {
    listUsers(USER_ROLES.SURGEON);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      ellipsis: true,
      sorter: (recordA, recordB) =>
        sortFields(recordA.firstName, recordB.firstName),
      filterIcon: filtered => iconFiltered(filtered),
      filterDropdown: filter => inputSearch(filter),
      onFilter: (value, record) => filterByText(value, record, "firstName")
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      ellipsis: true,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND,
      sorter: (recordA, recordB) =>
        sortFields(recordA.lastName, recordB.lastName),
      filterIcon: filtered => iconFiltered(filtered),
      filterDropdown: filter => inputSearch(filter),
      onFilter: (value, record) => filterByText(value, record, "lastName")
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ellipsis: true,
      sorter: (recordA, recordB) =>
        sortFields(recordA.username, recordB.username),
      filterIcon: filtered => iconFiltered(filtered),
      filterDropdown: filter => inputSearch(filter),
      onFilter: (value, record) => filterByText(value, record, "username")
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
      filters: [
        {
          text: capitalize(USER_ROLES.SURGEON),
          value: capitalize(USER_ROLES.SURGEON)
        }
      ],
      onFilter: (value, record) => record.role === value,
      sorter: (recordA, recordB) => sortFields(recordA.role, recordB.role)
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      sorter: (recordA, recordB) => sortFields(recordA.email, recordB.email),
      filterDropdown: filter => inputSearch(filter),
      filterIcon: filtered => iconFiltered(filtered),
      onFilter: (value, record) => filterByText(value, record, "email")
    },
    {
      title: "Status",
      dataIndex: "enabled",
      key: "enabled",
      ellipsis: true,
      filters: [
        {
          text: USER_STATUS.ENABLED,
          value: true
        },
        {
          text: USER_STATUS.DISABLED,
          value: false
        }
      ],
      onFilter: (value, record) => record.enabled === value,
      render: record => {
        const tagText = record ? USER_STATUS.ENABLED : USER_STATUS.DISABLED;
        return {
          props: {
            className: `status-${tagText.toLowerCase()}`
          },
          children: <Tag>{tagText}</Tag>
        };
      },
      sorter: (recordA, recordB) => sortFields(recordA.enabled, recordB.enabled)
    },
    {
      dataIndex: "actions",
      key: "actions",
      render: actionButtons
    }
  ];

  return (
    <GenericTable
      columns={columns}
      dataSource={userListData && parseDataSource(userListData)}
      title="Users"
      isLoading={!status || status === REQUEST_STATUS.LOADING}
      onRow={value => {
        return {
          onClick: () => {
            selectUser(
              {
                firstName: value.firstName,
                lastName: value.lastName,
                username: value.username,
                role: USER_ROLES.SURGEON,
                email: value.email,
                enabled: value.enabled
              },
              value.id
            );
            history.push(BO_LOCATION_PATH.ADD_USER);
          }
        };
      }}
    />
  );
};

UsersList.propTypes = {
  listUsers: PropTypes.func,
  selectUser: PropTypes.func,
  userListData: PropTypes.array,
  status: PropTypes.string,
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {
    userListData: state.userslist.users,
    status: state.userslist.status
  };
};

export default connect(mapStateToProps, { listUsers, selectUser })(UsersList);
