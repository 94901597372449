import { REQUEST_STATUS } from "gsi-ui-components";
import { REVIEWERS_LIST } from "../helpers/types";

const initialState = { status: null, reviewers: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case REVIEWERS_LIST.LOADING:
      return { ...state, status: REQUEST_STATUS.LOADING };
    case REVIEWERS_LIST.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        reviewers: action.payload
      };
    case REVIEWERS_LIST.ERROR:
      return { ...state, status: REQUEST_STATUS.ERROR };
    default:
      return state;
  }
};
