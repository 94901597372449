import { TICKET_DETAIL } from "../helpers/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TICKET_DETAIL.SET:
      return { ...state, ...action.payload };
    case TICKET_DETAIL.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
