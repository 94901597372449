import React from "react";
import PropTypes from "prop-types";
import { Layout, Button } from "antd";
import BreadcrumbContainer from "../common/BreadcrumbContainer";
import "./BoHeader.less";

const { Header } = Layout;

const BoHeader = ({
  title,
  breadcrumbRoutes = null,
  onButtonClick,
  buttonTitle,
  buttonIcon
}) => {
  return (
    <Header>
      <div className="header-container">
        <div className="header-title">
          {breadcrumbRoutes ? BreadcrumbContainer(breadcrumbRoutes) : title}
        </div>
        {buttonTitle && (
          <div className="header-button">
            <Button type="primary" onClick={onButtonClick} icon={buttonIcon}>
              {buttonTitle}
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
};

BoHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbRoutes: PropTypes.array,
  buttonTitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonIcon: PropTypes.node
};

export default React.memo(BoHeader);
