import { NEWS } from "../../helpers/types";

export const getNews = filters => async dispatch => {
  try {
    const [{ sendGetRequest }, { getNewsDtoInfo }, { getNewsUrl }] =
      await Promise.all([
        import(`../../helpers/URLHelper`),
        import(`../../helpers/newsHelper`),
        import(`gsi-ui-components`)
      ]);
    dispatch({
      type: NEWS.LOADING
    });
    const url = filters ? `${getNewsUrl()}?${filters}` : getNewsUrl();
    const response = await sendGetRequest(url);
    const newsData = getNewsDtoInfo(response.data);
    const totalRecords = response.data?.meta?.page?.totalRecords;

    dispatch({
      type: NEWS.SUCCESS,
      payload: { newsData, totalRecords }
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEWS.ERROR
    });
  }
};

export const getNewsById = id => async dispatch => {
  try {
    const [{ sendGetRequest }, { getNewsDtoInfo }, { getNewsUrl }] =
      await Promise.all([
        import(`../../helpers/URLHelper`),
        import(`../../helpers/newsHelper`),
        import(`gsi-ui-components`)
      ]);
    dispatch({
      type: NEWS.LOADING
    });
    const response = await sendGetRequest(getNewsUrl(id));
    const newsData = getNewsDtoInfo({ data: [response.data?.data] });
    dispatch({
      type: NEWS.SUCCESS,
      payload: { newsData, totalRecords: 1 }
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEWS.ERROR
    });
  }
};

export const clearNews = () => async dispatch => {
  dispatch({
    type: NEWS.CLEAR
  });
};
