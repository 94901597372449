import React from "react";
import { isEmpty } from "lodash";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  DATE_FORMAT,
  BO_LOCATION_PATH,
  SHORT_DATE_FORMAT,
  todayFormat,
  getCasesEndpoint,
  ASCII_ENCODE,
  SURGERY_CASE_ATTR,
  newCaseState,
  caseFilterType,
  checkValue
} from "gsi-ui-components";
import InputSearch from "../components/common/InputSearch";
import InputDate from "../components/common/InputDate";

export const filterCases = (
  filters,
  getCaseList,
  activeFilters,
  setFiltersCleared
) => {
  let filter;
  let requestParams = "";

  for (filter in filters) {
    let selectedFilter = filters[filter];
    if (!isEmpty(selectedFilter)) {
      let filterBy = filter;

      switch (filter) {
        case SURGERY_CASE_ATTR.CASE_STATUS:
          ({ selectedFilter, filterBy } = getStateFilter(
            filterBy,
            selectedFilter
          ));
          break;
        case SURGERY_CASE_ATTR.SURGERY_DATE:
          ({ selectedFilter, filterBy } = getSurgeryDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.LAST_MODIFIED:
          ({ selectedFilter, filterBy } =
            getLastModifiedDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.MODALITY:
          ({ selectedFilter, filterBy } = getModalityFilter(
            filterBy,
            selectedFilter
          ));
          break;
        case SURGERY_CASE_ATTR.PATIENT_NAME:
        case SURGERY_CASE_ATTR.MRN:
        case SURGERY_CASE_ATTR.CLASSIFICATION:
        case SURGERY_CASE_ATTR.CASE_NUMBER:
          ({ selectedFilter, filterBy } = getTextFilter(
            selectedFilter,
            filter
          ));
          break;
        default:
          break;
      }
      requestParams = requestParams.concat(`&${filterBy}=${selectedFilter}`);
    }
  }

  setFiltersCleared(isEmpty(filters));

  activeFilters.current = requestParams;

  getCaseList(`${getCasesEndpoint()}${requestParams}`);
};

const getTextFilter = (selectedFilter, filter) => {
  return {
    selectedFilter: `${ASCII_ENCODE.PERCENT}${encodeURIComponent(
      selectedFilter
    )}${ASCII_ENCODE.PERCENT}`,
    filterBy: caseFilterType[filter]
  };
};

const getStateFilter = (_filterBy, selectedFilter) => {
  const filterByValue = `${SURGERY_CASE_ATTR.CASE_STATUS}`;
  return {
    selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE),
    filterBy: filterByValue
  };
};

const getModalityFilter = (_filterBy, selectedFilter) => {
  const filterByValue = `${SURGERY_CASE_ATTR.MODALITY}`;
  return {
    selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE),
    filterBy: filterByValue
  };
};

export const getLastModifiedDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = new Date(filteredDates[0]);
  firstDate.setUTCHours(0, 0, 0, 0);
  const firstDateIso = firstDate.toISOString();

  const secondDate = new Date(filteredDates[1]);
  secondDate.setUTCHours(23, 59, 59, 999);
  const secondDateIso = secondDate.toISOString();

  return {
    selectedFilter: `${firstDateIso}~${secondDateIso}`,
    filterBy: "lastModifiedPlanningDate"
  };
};

const getSurgeryDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = moment(filteredDates[0], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  const secondDate = moment(filteredDates[1], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  return {
    selectedFilter: `${firstDate}~${secondDate}`,
    filterBy: SURGERY_CASE_ATTR.SURGERY_DATE
  };
};

export const parseInputData = caseList => {
  const parsedData = [];
  caseList.forEach((currentCase, index) => {
    const surgeryDate =
      currentCase?.date && moment(currentCase.date).format(SHORT_DATE_FORMAT);
    const lastModifiedDate = moment(
      currentCase?.planningMetadata?.lastModifiedPlanningDate
    ).format(SHORT_DATE_FORMAT);

    const parsedCase = {
      key: index,
      [SURGERY_CASE_ATTR.ID]: checkValue(currentCase?.id),
      [SURGERY_CASE_ATTR.PATIENT_NAME]: checkValue(
        currentCase?.patient.fullName
      ),
      [SURGERY_CASE_ATTR.MODALITY]: checkValue(currentCase?.modality),
      [SURGERY_CASE_ATTR.MRN]: checkValue(currentCase?.medicalRecordNumber),
      [SURGERY_CASE_ATTR.SURGERY_DATE]: checkValue(todayFormat(surgeryDate)),
      [SURGERY_CASE_ATTR.CASE_STATUS]: checkValue(
        newCaseState[currentCase?.state]
      ),
      [SURGERY_CASE_ATTR.CLASSIFICATION]: checkValue(
        currentCase?.classification
      ),
      [SURGERY_CASE_ATTR.LAST_MODIFIED]: checkValue(lastModifiedDate),
      [SURGERY_CASE_ATTR.CASE_NUMBER]: currentCase?.caseNumber,
      [SURGERY_CASE_ATTR.SURGEON_ID]: checkValue(currentCase?.userId),
      [SURGERY_CASE_ATTR.SURGEON_NAME]: checkValue(currentCase?.surgeonName),
      [SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER]: checkValue(
        currentCase?.originalCaseNumber
      )
    };
    parsedData.push(parsedCase);
  });
  return parsedData;
};

export const getCaseSearchPath = caseId => {
  return `${BO_LOCATION_PATH.SURGERY_CASES}/${caseId}`;
};

export const getCasesOfTicketPath = ticketId => {
  return `${BO_LOCATION_PATH.REQUEST_SUPPORT}/${ticketId}`;
};

export const inputSearch = (filter, placeholder) => (
  <InputSearch
    setSelectedKeys={filter.setSelectedKeys}
    selectedKeys={filter.selectedKeys}
    confirm={filter.confirm}
    clearFilters={filter.clearFilters}
    placeholder={placeholder}
  />
);

export const rangePicker = (setSelectedKeys, confirm) => {
  return <InputDate setSelectedKeys={setSelectedKeys} confirm={confirm} />;
};

export const iconFilteredCalendar = filtered => (
  <CalendarOutlined className={filtered ? "filtered-date" : ""} />
);

export const iconFiltered = filtered => (
  <SearchOutlined className={filtered ? "filtered-date" : ""} />
);
