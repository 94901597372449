import { isEmpty } from "lodash";
import { REQUEST_STATUS } from "gsi-ui-components";
import { REQUEST_SUPPORT_CASES } from "../helpers/types";

const initialState = {
  status: null,
  responseData: {},
  caseList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SUPPORT_CASES.LOADING:
      return { ...state, status: REQUEST_STATUS.LOADING };
    case REQUEST_SUPPORT_CASES.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        responseData: action.payload,
        caseList: !isEmpty(action.payload.entities)
          ? action.payload.entities
          : initialState.caseList
      };
    case REQUEST_SUPPORT_CASES.ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.ERROR
      };
    case REQUEST_SUPPORT_CASES.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
