import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { LoadingOutlined, NumberOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, Layout, Upload, Spin, message, Select } from "antd";
import { connect } from "react-redux";
import { Link, Redirect, useParams } from "react-router-dom";
import { triggerBuild } from "../../actions/jenkins";
import { ReactComponent as UploadIcon } from "../../resources/requestSupport/upload.svg";
import BoHeader from "../layout/BoHeader";
import {
  resetPlanOptions,
  userService,
  HISTORY_ACTION,
  CLONE_CASE_PARAM,
  BO_LOCATION_PATH,
  TICKET_FILES_CONFIG,
  REQUEST_STATUS,
  UPDATE_CASE_PARAM,
  PIPELINE_COMMON_PARAM
} from "gsi-ui-components";
import { getTicketReviewers, getCaseTaskInfo } from "../../actions/tickets";
import {
  parseReviewerDataSource,
  getInputFilesText,
  getCaseTaskSubtitle,
  CASE_TASKS,
  getTaskTypesByParam
} from "../../helpers/ticketsHelper";
import { ERROR_MESSAGE } from "../../helpers/consts";
import "./CaseTask.less";

const { Content } = Layout;
const { Dragger } = Upload;

export const uploadTicketFiles = (
  fileInfoList,
  fileInfo,
  currentFileList,
  setCurrentFileList
) => {
  if (fileInfoList.length === TICKET_FILES_CONFIG.MAX_FILES) {
    return;
  }

  let fileAlreadyUploaded = false;
  fileInfo.status !== TICKET_FILES_CONFIG.STATUSES.REMOVED &&
    currentFileList.forEach(file => {
      if (file.type === fileInfo.type) {
        fileAlreadyUploaded = true;
      }
    });

  if (!fileAlreadyUploaded) {
    setCurrentFileList(fileInfoList);
  }
};

const makeRequestBody = (values, task, userId, caseId, buildParameters) => {
  let pipelineValues = {
    [PIPELINE_COMMON_PARAM.TARGET_USER]: values.targetUser
  };
  if (task === CASE_TASKS.INJECT.ENGINEER) {
    const backofficeUserId = userService.getUserId();
    pipelineValues = {
      ...pipelineValues,
      [PIPELINE_COMMON_PARAM.TARGET_USER]: backofficeUserId
    };
  } else if (task === CASE_TASKS.INJECT.USER) {
    pipelineValues = {
      ...pipelineValues,
      [PIPELINE_COMMON_PARAM.TARGET_USER]: userId
    };
  } else if (task === CASE_TASKS.UPDATE.USER) {
    pipelineValues = {
      ...pipelineValues,
      [UPDATE_CASE_PARAM.TARGET_USER]: userId,
      [UPDATE_CASE_PARAM.CASE_ID]: caseId,
      [UPDATE_CASE_PARAM.RESET_PLAN_STATUS]: values.resetPlanStatus
    };
  } else if (task === CASE_TASKS.CLONE) {
    pipelineValues = {
      ...pipelineValues,
      [CLONE_CASE_PARAM.CASE_ID]: caseId
    };
  }
  return {
    ...buildParameters,
    ...pipelineValues
  };
};

const getTaskFormItem = (
  task,
  getFieldDecorator,
  reviewersList,
  caseId,
  fileList,
  setFileList,
  filesInfo
) => {
  const fileFormat = task !== CASE_TASKS.UPDATE.USER ? ".json, " : "";
  return (
    <div>
      {task === CASE_TASKS.INJECT.VERIFIER && (
        <Form.Item>
          {getFieldDecorator("targetUser", {
            rules: [
              {
                required: true,
                message: "Please select a reviewer!"
              }
            ]
          })(
            <Select placeholder="Reviewer">
              {reviewersList.map(reviewer => (
                <Select.Option value={reviewer.userId} key={reviewer.userId}>
                  {reviewer.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      )}
      {task === CASE_TASKS.ANNOTATION2CASE.VERIFIER && (
        <Form.Item>
          {getFieldDecorator("targetUser", {
            rules: [
              {
                required: true,
                message: "Please select a reviewer!"
              }
            ]
          })(
            <Select placeholder="Reviewer">
              <Select.Option value={userService.getUserId()}>
                {userService.getName()}
              </Select.Option>
            </Select>
          )}
        </Form.Item>
      )}
      {task === CASE_TASKS.UPDATE.USER && (
        <Form.Item>
          {getFieldDecorator("resetPlanStatus", {
            rules: [
              {
                required: true,
                message: "Please select an option"
              }
            ]
          })(
            <Select placeholder="Reset plan Status">
              {resetPlanOptions.map(option => (
                <Select.Option value={option.value} key={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      )}
      {task === CASE_TASKS.UPDATE.USER && (
        <Form.Item>
          {
            <Input
              prefix={<NumberOutlined className="icon-color" />}
              placeholder={caseId}
              disabled={true}
            />
          }
        </Form.Item>
      )}
      {![CASE_TASKS.CLONE, CASE_TASKS.ANNOTATION2CASE.VERIFIER].includes(
        task
      ) && (
        <Form.Item>
          {getFieldDecorator("file", {
            valuePropName: "files",
            getValueFromEvent: fileListValue => fileListValue,
            rules: [
              {
                required: true,
                message: "Please add JSON and ZIP segmentation files!"
              }
            ]
          })(
            <Dragger
              accept={`${fileFormat}.zip`}
              name="file"
              maxCount={2}
              multiple={true}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok");
                }, 0);
              }}
              fileList={fileList}
              onChange={fileInfo =>
                uploadTicketFiles(
                  fileInfo.fileList,
                  fileInfo.file,
                  fileList,
                  setFileList
                )
              }
              beforeUpload={file => {
                const reader = new FileReader();
                reader.onload = e =>
                  filesInfo.current.push({
                    name: file.name,
                    content: e.target.result,
                    type: file.type
                  });
                reader.readAsArrayBuffer(file);
              }}
            >
              <div className="container">
                <p className="ant-upload-drag-icon">
                  <UploadIcon />
                </p>
                <p className="ant-upload-drag-message">
                  Click here or drag and drop
                </p>
              </div>
            </Dragger>
          )}
        </Form.Item>
      )}
    </div>
  );
};

const CaseTask = ({
  caseId,
  userId,
  history,
  form,
  buildStatus,
  triggerBuild,
  getTicketReviewers,
  reviewers,
  getCaseTaskInfo,
  task
}) => {
  const { getFieldDecorator, validateFields } = form;
  const [fileList, setFileList] = useState([]);
  const [reviewersList, setReviewersList] = useState([]);
  const filesInfo = useRef([]);

  const params = useParams();
  const { taskTypeParam, ticketIdParam } = params;
  const { taskType, taskSubType } = getTaskTypesByParam(taskTypeParam);

  useEffect(() => {
    if (buildStatus === REQUEST_STATUS.SUCCESS) {
      history.push(`/casetask/${taskTypeParam}/${ticketIdParam}/finish`);
    } else if (buildStatus === REQUEST_STATUS.ERROR) {
      message.error(ERROR_MESSAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildStatus]);

  useEffect(() => {
    if (task === CASE_TASKS.INJECT.VERIFIER) {
      getTicketReviewers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useEffect(() => {
    if (task === CASE_TASKS.INJECT.VERIFIER) {
      setReviewersList(parseReviewerDataSource(reviewers.reviewers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewers]);

  useEffect(() => {
    ticketIdParam && getCaseTaskInfo(ticketIdParam, taskType, taskSubType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketIdParam]);

  const buildParameters = {
    [PIPELINE_COMMON_PARAM.ISSUE_ID]: ticketIdParam
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const body = makeRequestBody(
          values,
          task,
          userId,
          caseId,
          buildParameters
        );
        body.filesInfo = filesInfo.current;
        triggerBuild(body);
      }
    });
  };

  return history.action !== HISTORY_ACTION.POP ? (
    <Layout>
      <BoHeader title="Request Support" />
      {task && (
        <Content>
          <div className="case-task">
            <h1 className="task-name">{task}</h1>
            <div className="task-subtitle">
              {getCaseTaskSubtitle(task, userId)}
            </div>
            <p className="task-link">{getInputFilesText(task)}</p>
            <div className="ticket-info">
              <>
                <Spin
                  tip="Uploading..."
                  indicator={<LoadingOutlined />}
                  spinning={buildStatus === REQUEST_STATUS.LOADING}
                >
                  <Form
                    autoComplete="off"
                    id="pipelineForm"
                    onSubmit={handleSubmit}
                  >
                    {getTaskFormItem(
                      task,
                      getFieldDecorator,
                      reviewersList,
                      caseId,
                      fileList,
                      setFileList,
                      filesInfo
                    )}
                  </Form>
                  <div className="footer-buttons">
                    <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT}>
                      <Button type="default" className="btn-back">
                        Back
                      </Button>
                    </Link>
                    <Button
                      form="pipelineForm"
                      key="submit"
                      htmlType="submit"
                      type="primary"
                    >
                      Next
                    </Button>
                  </div>
                </Spin>
              </>
            </div>
          </div>
        </Content>
      )}
    </Layout>
  ) : (
    <Redirect to={{ pathname: BO_LOCATION_PATH.ROOT }} />
  );
};

CaseTask.propTypes = {
  caseId: PropTypes.string,
  userId: PropTypes.string,
  history: PropTypes.object,
  form: PropTypes.object,
  triggerBuild: PropTypes.func,
  buildStatus: PropTypes.string,
  getTicketReviewers: PropTypes.func,
  reviewers: PropTypes.object,
  getCaseTaskInfo: PropTypes.func,
  task: PropTypes.string
};

const mapStateToProps = state => {
  return {
    caseId: state.caseTask.caseId,
    userId: state.caseTask.userId,
    buildStatus: state.jenkins.status,
    reviewers: state.reviewers,
    task: state.caseTask.task
  };
};

export default connect(mapStateToProps, {
  triggerBuild,
  getTicketReviewers,
  getCaseTaskInfo
})(Form.create()(CaseTask));
