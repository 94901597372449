export const changeLocation = newLocation => async dispatch => {
  const [{ LOCATION_CHANGED }, { BO_PAGE_TITLE }] = await Promise.all([
    import("../helpers/types"),
    import("../helpers/consts")
  ]);
  document.title = BO_PAGE_TITLE;
  dispatch({
    type: LOCATION_CHANGED,
    payload: newLocation
  });
};
