import { requestStatus } from "gsi-ui-components";
import { NOTIFICATIONS_EDIT } from "../../helpers/types";

const initialState = {
  status: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_EDIT.LOADING:
      return { ...initialState, status: requestStatus.LOADING };
    case NOTIFICATIONS_EDIT.SUCCESS:
      return {
        ...initialState,
        status: requestStatus.SUCCESS
      };
    case NOTIFICATIONS_EDIT.ERROR:
      return { ...initialState, status: requestStatus.ERROR };
    case NOTIFICATIONS_EDIT.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
