import { useEffect, useRef } from "react";
import { message } from "antd";
import { REQUEST_STATUS } from "gsi-ui-components";
import { showCloneStatusMessage } from "../helpers/caseListHelper";

const useCloneCase = (cloneCaseStatus, clearCloneCaseData, getCaseList) => {
  const cloneInfo = useRef({});

  useEffect(() => {
    if (cloneInfo.current) {
      showCloneStatusMessage(
        cloneInfo.current.patientName,
        cloneCaseStatus,
        message,
        clearCloneCaseData
      );
    }
    if (cloneCaseStatus === REQUEST_STATUS.SUCCESS) {
      getCaseList();
    }
  }, [cloneCaseStatus, clearCloneCaseData, getCaseList]);

  return cloneInfo;
};

export default useCloneCase;
