import { requestStatus } from "gsi-ui-components";
import { NOTIFICATIONS } from "../../helpers/types";

const initialState = {
  notificationsData: null,
  status: null,
  totalRecords: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS.LOADING:
      return { ...initialState, status: requestStatus.LOADING };
    case NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notificationsData: action.payload?.notificationsData,
        totalRecords: action.payload?.totalRecords,
        status: requestStatus.SUCCESS
      };
    case NOTIFICATIONS.ERROR:
      return { ...initialState, status: requestStatus.ERROR };
    case NOTIFICATIONS.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
