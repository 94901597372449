import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BaseModal } from "gsi-ui-components";
import { cloneCase } from "../../../actions/cases";
import { buttonConsts } from "../../../helpers/consts";

const CloneCaseModal = ({ setIsVisible, isVisible, cloneCase, cloneInfo }) => {
  return (
    <BaseModal
      handleCancel={() => setIsVisible(false)}
      config={{
        visible: isVisible,
        maskClosable: false,
        title: "Clone case",
        footer: [
          {
            config: {
              type: buttonConsts.TYPE.LINK,
              size: buttonConsts.SIZE.DEFAULT
            },
            onClick: () => {
              setIsVisible(false);
            },
            text: "Cancel"
          },
          {
            config: {
              type: buttonConsts.TYPE.PRIMARY,
              size: buttonConsts.SIZE.DEFAULT
            },
            onClick: () => {
              cloneCase(cloneInfo.current.caseNumber);
              setIsVisible(false);
            },
            text: "Clone case"
          }
        ]
      }}
    >
      <div>You are about to clone this case to your library.</div>
    </BaseModal>
  );
};

CloneCaseModal.propTypes = {
  setIsVisible: PropTypes.func,
  isVisible: PropTypes.bool,
  cloneCase: PropTypes.func,
  cloneInfo: PropTypes.object
};

export default connect(null, {
  cloneCase
})(CloneCaseModal);
