import axios from "axios";
import md5 from "js-md5";
import { isEmpty } from "lodash";
import { CLONE_CASE_PARAM, getTaskUrl } from "gsi-ui-components";
import { CASE_TASKS } from "../helpers/ticketsHelper";

const SURGERY_CASE_TYPE = "surgerycase";
const HASH_HEADER = "Content-MD5";

const transformRequest = (data, headers) => {
  delete headers.common["Content-Type"];
  delete headers.put["Content-Type"];
  return data;
};

const commonConfig = {
  method: "PUT",
  transformRequest: [(data, headers) => transformRequest(data, headers)]
};

const sendFilesToBackend = async (body, taskId, taskStatus, task) => {
  const { BODY_PARSER, JSON_CONTENT_TYPE, sendPutRequest } = await import(
    `../helpers/URLHelper`
  );

  const filesInfo = {};
  const filesData = {};

  const caseId = body[CLONE_CASE_PARAM.CASE_ID];
  const taskIsInjectCaseType = [
    CASE_TASKS.INJECT.USER,
    CASE_TASKS.INJECT.ENGINEER,
    CASE_TASKS.INJECT.VERIFIER
  ].includes(task);
  const bodyFilesInfo = body.filesInfo;

  if (!isEmpty(bodyFilesInfo)) {
    if (taskIsInjectCaseType && bodyFilesInfo.length < 2) {
      throw new Error("Please add JSON and ZIP segmentation files");
    }
    bodyFilesInfo.forEach(file => {
      const fileName = file.name;
      const fileContent = file.content;
      if (file.type === JSON_CONTENT_TYPE) {
        filesInfo.jsonFile = fileName;
        filesInfo.jsonFileChecksum = md5.base64(fileContent);
        filesData.jsonFileContent = fileContent;
      } else {
        filesInfo.supportSegmentationFile = fileName;
        filesInfo.supportSegmentationFileChecksum = md5.base64(fileContent);
        filesData.supportSegmentationFileContent = fileContent;
      }
    });
  }

  const parameters = {
    impersonate: true,
    targetUser: body.TARGET_USER,
    ...filesInfo
  };
  if (caseId) {
    parameters.caseId = caseId;
  }

  const taskResponse = await sendPutRequest(
    getTaskUrl(),
    BODY_PARSER.JSON,
    {
      target: SURGERY_CASE_TYPE,
      id: taskId,
      taskStatus: taskStatus,
      parameters
    },
    JSON_CONTENT_TYPE
  );

  const taskResponseData = taskResponse.data.entities[0];
  const taskResponseOutput = taskResponseData.output;

  const storageUploadJobs = [];

  taskIsInjectCaseType &&
    storageUploadJobs.push(
      axios({
        ...commonConfig,
        url: taskResponseOutput.jsonFileSignedURL,
        data: filesData.jsonFileContent,
        headers: {
          [HASH_HEADER]: filesInfo.jsonFileChecksum
        }
      })
    );

  storageUploadJobs.push(
    axios({
      ...commonConfig,
      url: taskResponseOutput.supportSegmentationFileSignedURL,
      data: filesData.supportSegmentationFileContent,
      headers: {
        [HASH_HEADER]: filesInfo.supportSegmentationFileChecksum
      }
    })
  );
  await Promise.all(storageUploadJobs);
};

export const triggerBuild = body => async (dispatch, getState) => {
  const [
    { JENKINS_JOB },
    { BODY_PARSER, JSON_CONTENT_TYPE, sendPatchRequest, sendPutRequest },
    { TASK_STATUS_EXECUTE }
  ] = await Promise.all([
    import(`../helpers/types`),
    import(`../helpers/URLHelper`),
    import(`../helpers/ticketsHelper`)
  ]);

  try {
    dispatch({
      type: JENKINS_JOB.LOADING
    });

    const caseTask = getState().caseTask;

    if (caseTask.task === CASE_TASKS.ANNOTATION2CASE.VERIFIER) {
      await sendPutRequest(
        getTaskUrl(),
        BODY_PARSER.JSON,
        {
          target: SURGERY_CASE_TYPE,
          id: caseTask.id,
          taskType: caseTask.taskType,
          taskStatus: caseTask.state,
          parameters: {
            targetUser: body.TARGET_USER
          }
        },
        JSON_CONTENT_TYPE
      );
    } else {
      await sendFilesToBackend(
        body,
        caseTask.id,
        caseTask.state,
        caseTask.task
      );
    }

    await sendPatchRequest(
      getTaskUrl(caseTask.id),
      BODY_PARSER.JSON,
      {
        taskStatus: TASK_STATUS_EXECUTE
      },
      JSON_CONTENT_TYPE
    );

    dispatch({
      type: JENKINS_JOB.SUCCESS
    });
  } catch {
    dispatch({
      type: JENKINS_JOB.ERROR
    });
  }
};

export const clearBuildStatus = () => async dispatch => {
  const { JENKINS_JOB } = await import(`../helpers/types`);

  dispatch({
    type: JENKINS_JOB.CLEAR
  });
};
