import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Layout } from "antd";
import { BO_LOCATION_PATH } from "gsi-ui-components";
import BoHeader from "../../layout/BoHeader";
import { ReactComponent as SuccessIcon } from "../../../resources/requestSupport/success.svg";
import "./ResultPage.less";

const { Content } = Layout;

const ResultPage = ({ pipelineUrl }) => {
  return (
    <Layout>
      <BoHeader title="Request Support" />
      <Content>
        <div className="case-info">
          <div className="ticket-info">
            <SuccessIcon className="result-icon" />
            <h1 className="title">Your case is being injected</h1>
            {pipelineUrl && (
              <a
                href={pipelineUrl}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                View in Jenkins
              </a>
            )}
            <a href={BO_LOCATION_PATH.REQUEST_SUPPORT}>
              <Button type="primary">Done</Button>
            </a>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

ResultPage.propTypes = {
  pipelineUrl: PropTypes.string
};

const mapStateToProps = state => {
  return {
    pipelineUrl: state.caseTask.url
  };
};

export default React.memo(connect(mapStateToProps, {})(ResultPage));
