import { NEWS_EDIT } from "../../helpers/types";

export const submitNews =
  (data, id = null) =>
  async dispatch => {
    try {
      const [
        { sendPostRequest, sendPatchRequest },
        { buildNewsReqBody },
        { BODY_PARSER, JSON_CONTENT_TYPE, getNewsUrl }
      ] = await Promise.all([
        import(`../../helpers/URLHelper`),
        import(`../../helpers/newsHelper`),
        import(`gsi-ui-components`)
      ]);
      dispatch({
        type: NEWS_EDIT.LOADING
      });
      const body = buildNewsReqBody(data);
      if (id) {
        await sendPatchRequest(
          `${getNewsUrl()}/${id}`,
          BODY_PARSER.JSON,
          { data: { ...body.data, id } },
          JSON_CONTENT_TYPE
        );
      } else {
        await sendPostRequest(
          getNewsUrl(),
          BODY_PARSER.JSON,
          body,
          JSON_CONTENT_TYPE
        );
      }
      dispatch({
        type: NEWS_EDIT.SUCCESS
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: NEWS_EDIT.ERROR
      });
    }
  };

export const clearSubmitNewsStatus = () => async dispatch => {
  dispatch({
    type: NEWS_EDIT.CLEAR
  });
};
