export const LOCATION_CHANGED = "LOCATION_CHANGED";

export const SIGN_OUT = "SIGN_OUT";

export const SELECT_USER = "SELECT_USER";

export const KEYCLOAK_USER_ROLES_ACTION = "KEYCLOAK_USER_ROLES_ACTION";

export const USER_ACTION_RELATED = {
  LOADING: "USER_ACTION_RELATED_LOADING",
  SUCCESS: "USER_ACTION_RELATED_SUCCESS",
  ERROR: "USER_ACTION_RELATED_ERROR",
  CLEAR: "USER_ACTION_RELATED_CLEAR",
  RESET: "USER_ACTION_RELATED_RESET"
};

export const RESET_PASSWORD = {
  LOADING: "RESET_PASSWORD_LOADING",
  SUCCESS: "RESET_PASSWORD_SUCCESS",
  ERROR: "RESET_PASSWORD_ERROR",
  CLEAR: "RESET_PASSWORD_CLEAR"
};

export const USERS_LIST = {
  LOADING: "USERS_LIST_LOADING",
  SUCCESS: "USERS_LIST_SUCCESS",
  ERROR: "USERS_LIST_ERROR"
};

export const CASE_LIST = {
  LOADING: "CASE_LIST_LOADING",
  SUCCESS: "CASE_LIST_SUCCESS",
  ERROR: "CASE_LIST_ERROR",
  CLEAR: "CASE_LIST_CLEAR",
  GET_TOTAL_ITEMS: "CASE_LIST_GET_TOTAL_ITEMS"
};

export const CLONE_CASE = {
  LOADING: "CLONE_CASE_LOADING",
  SUCCESS: "CLONE_CASE_SUCCESS",
  ERROR: "CLONE_CASE_ERROR",
  CLEAR: "CLONE_CASE_CLEAR"
};

export const TICKET_LIST = {
  LOADING: "TICKET_LIST_LOADING",
  SUCCESS: "TICKET_LIST_SUCCESS",
  ERROR: "TICKET_LIST_ERROR",
  CLEAR: "TICKET_LIST_CLEAR"
};

export const TICKET_TASKS = {
  LOADING: "TICKET_TASKS_LOADING",
  SUCCESS: "TICKET_TASKS_SUCCESS",
  ERROR: "TICKET_TASKS_ERROR",
  CLEAR: "TICKET_TASKS_CLEAR"
};

export const TICKET_UPDATE_TASKS = {
  LOADING: "TICKET_UPDATE_TASKS_LOADING",
  SUCCESS: "TICKET_UPDATE_TASKS_SUCCESS",
  ERROR: "TICKET_UPDATE_TASKS_ERROR",
  CLEAR: "TICKET_UPDATE_TASKS_CLEAR"
};

export const CASE_TASK = {
  LOADING: "CASE_TASK_LOADING",
  SUCCESS: "CASE_TASK_SUCCESS",
  ERROR: "CASE_TASK_ERROR",
  SET: "CASE_TASK_SET",
  CLEAR: "CASE_TASK_CLEAR"
};

export const TICKET_DETAIL = {
  SET: "TICKET_DETAIL_SET",
  CLEAR: "TICKET_DETAIL_CLEAR"
};

export const JENKINS_JOB = {
  LOADING: "JENKINS_JOB_LOADING",
  SUCCESS: "JENKINS_JOB_SUCCESS",
  ERROR: "JENKINS_JOB_ERROR",
  CLEAR: "JENKINS_JOB_CLEAR"
};

export const REVIEWERS_LIST = {
  LOADING: "REVIEWERS_LIST_LOADING",
  SUCCESS: "REVIEWERS_LIST_SUCCESS",
  ERROR: "REVIEWERS_LIST_ERROR"
};

export const CASE_INFO = {
  LOADING: "CASE_INFO_LOADING",
  SUCCESS: "CASE_INFO_SUCCESS",
  ERROR: "CASE_INFO_ERROR",
  CLEAR: "CASE_INFO_CLEAR"
};

export const PLANNING_INFO = {
  LOADING: "PLANNING_INFO_LOADING",
  SUCCESS: "PLANNING_INFO_SUCCESS",
  ERROR: "PLANNING_INFO_ERROR",
  CLEAR: "PLANNING_INFO_CLEAR"
};

export const REQUEST_SUPPORT_CASES = {
  LOADING: "REQUEST_SUPPORT_CASES_LOADING",
  SUCCESS: "REQUEST_SUPPORT_CASES_SUCCESS",
  ERROR: "REQUEST_SUPPORT_CASES_ERROR",
  CLEAR: "REQUEST_SUPPORT_CASES_CLEAR"
};

export const LOAD_IMPLANTS_INFO = {
  SUCCESS: "LOAD_IMPLANTS_INFO_SUCCESS",
  LOADING: "LOAD_IMPLANTS_INFO_LOADING",
  ERROR: "LOAD_IMPLANTS_INFO_ERROR"
};

export const NEWS = {
  SUCCESS: "NEWS_SUCCESS",
  LOADING: "NEWS_LOADING",
  ERROR: "NEWS_ERROR",
  CLEAR: "NEWS_CLEAR"
};

export const NEWS_EDIT = {
  SUCCESS: "NEWS_EDIT_SUCCESS",
  LOADING: "NEWS_EDIT_LOADING",
  ERROR: "NEWS_EDIT_ERROR",
  CLEAR: "NEWS_EDIT_CLEAR"
};

export const NOTIFICATIONS = {
  SUCCESS: "NOTIFICATIONS_SUCCESS",
  LOADING: "NOTIFICATIONS_LOADING",
  ERROR: "NOTIFICATIONS_ERROR",
  CLEAR: "NOTIFICATIONS_CLEAR"
};

export const NOTIFICATIONS_EDIT = {
  SUCCESS: "NOTIFICATIONS_EDIT_SUCCESS",
  LOADING: "NOTIFICATIONS_EDIT_LOADING",
  ERROR: "NOTIFICATIONS_EDIT_ERROR",
  CLEAR: "NOTIFICATIONS_EDIT_CLEAR"
};
