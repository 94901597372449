import moment from "moment";
import { isEmpty } from "lodash";
import {
  ASCII_ENCODE,
  CASE_TICKET_ATTR,
  ALIASES,
  UPDATE_CASE_PARAM,
  SUPPORT_STATUS,
  DATE_FORMAT,
  START_INDEX_PARAM,
  PAGE_SIZE_PARAM,
  getTicketsDefaultSortBy,
  getTicketsEndpoint,
  checkValue,
  LIST_DEFAULT_VALUE
} from "gsi-ui-components";

export const CASE_TASKS_PIPELINES = {
  CLONE: "inject-clone-case-segmentation",
  INJECT: "inject-new-case",
  UPDATE: "inject-update-case",
  ANNOTATION2CASE: "execute-annotation2case"
};

export const TASK_TYPE = {
  INJECT: "Inject case",
  UPDATE: "Update case",
  ANNOTATION2CASE: "Create case"
};

export const CASE_TASKS = {
  CLONE: "Clone Case",
  INJECT: {
    ENGINEER: "Inject to Engineer",
    VERIFIER: "Inject to Verifier",
    USER: "Inject to User"
  },
  UPDATE: { USER: "Update Case" },
  ANNOTATION2CASE: {
    VERIFIER: "Create new case from annotations"
  }
};

export const getNextRequestQuery = (
  requestData,
  page,
  activeFilters,
  pageSize
) => {
  const selfUrl = requestData.links?.self;
  const startIndex = pageSize * (page - 1);
  const nextQuery = `${selfUrl}${START_INDEX_PARAM}${startIndex}${PAGE_SIZE_PARAM}${pageSize}`;
  return `${nextQuery}${getTicketsDefaultSortBy()}${activeFilters}`;
};

export const getTotalTickets = requestData => {
  return requestData.meta?.count;
};

export const parseInputData = (ticketList, responseData) => {
  const parsedData = [];
  const relationships = responseData.included || [];
  ticketList.forEach((currentCase, index) => {
    const { providerKey, providerId } = getProviderKeyIdFromResponse(
      relationships,
      currentCase.relationships.supportRequestProvider?.data?.id
    );
    const parsedCase = {
      key: index,
      [CASE_TICKET_ATTR.ID]: checkValue(providerKey),
      [CASE_TICKET_ATTR.PATIENT_NAME]: checkValue(),
      [CASE_TICKET_ATTR.CASE_ID]: checkValue(
        currentCase.relationships.surgeryCase?.data?.id
      ),
      [CASE_TICKET_ATTR.CASE_NUMBER]: checkValue(
        currentCase.attributes.caseNumber
      ),
      [CASE_TICKET_ATTR.STATE]: checkValue(
        SUPPORT_STATUS.TEXT[currentCase.attributes.state?.toUpperCase()]
      ),
      [CASE_TICKET_ATTR.CREATED_DATE]: checkValue(
        currentCase.attributes.createdDate
      ),
      [CASE_TICKET_ATTR.REASON]: checkValue(),
      [CASE_TICKET_ATTR.USER_ID]: checkValue(
        currentCase.relationships.caseOwnerId?.data?.id
      ),
      [CASE_TICKET_ATTR.ISSUE_ID]: providerId
    };
    parsedData.push(parsedCase);
  });

  return parsedData;
};

export const getInputFilesText = task => {
  switch (task) {
    case CASE_TASKS.CLONE:
      return `Please enter the case information`;
    case CASE_TASKS.INJECT.ENGINEER:
    case CASE_TASKS.INJECT.VERIFIER:
    case CASE_TASKS.INJECT.USER:
      return `Please add JSON and ZIP segmentation files`;
    case CASE_TASKS.UPDATE.USER:
      return `Please add ZIP segmentation file`;
    default:
      break;
  }
};

export const TASKS_INFO = [
  { title: "Get Files" },
  { title: "Segment" },
  {
    title: "Upgrade Case",
    content: "Clone case",
    pipelineName: CASE_TASKS_PIPELINES.CLONE,
    task: CASE_TASKS.CLONE
  },
  {
    title: "Send to Engineer",
    content: CASE_TASKS.INJECT,
    pipelineName: CASE_TASKS_PIPELINES.INJECT,
    task: CASE_TASKS.INJECT.ENGINEER
  },
  {
    title: "Send to Verifier",
    content: CASE_TASKS.INJECT,
    pipelineName: CASE_TASKS_PIPELINES.INJECT,
    task: CASE_TASKS.INJECT.VERIFIER
  },
  {
    title: "Send to User",
    content: CASE_TASKS.INJECT,
    pipelineName: CASE_TASKS_PIPELINES.INJECT,
    task: CASE_TASKS.INJECT.USER
  }
];

export const TASK_STATUS = {
  null: "new",
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  ERROR: "error"
};

export const TASK_STATUS_EXECUTE = "EXECUTE";

export const filterTickets = (filters, getTicketList, activeFilters) => {
  let filter;
  let requestParams = "";

  for (filter in filters) {
    let selectedFilter = filters[filter];
    if (!isEmpty(selectedFilter)) {
      let filterBy = filter;
      if (filter === CASE_TICKET_ATTR.STATE) {
        ({ selectedFilter, filterBy } = getStateFilter(
          filterBy,
          selectedFilter
        ));
      } else if (filter === CASE_TICKET_ATTR.CREATED_DATE) {
        selectedFilter = getCreatedDateFilter(selectedFilter);
      } else if (filter === CASE_TICKET_ATTR.ID) {
        ({ selectedFilter, filterBy } = getIssueKeyFilter(selectedFilter));
      }
      requestParams = requestParams.concat(`&${filterBy}=${selectedFilter}`);
    }
  }
  activeFilters.current = requestParams;
  getTicketList(`${getTicketsEndpoint()}${requestParams}`);
};

const getStateFilter = (_filterBy, selectedFilter) => {
  const filterByValue = `ticket.${CASE_TICKET_ATTR.STATE}`;
  return {
    selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE),
    filterBy: filterByValue
  };
};

const getCreatedDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstCreatedDate = moment(filteredDates[0], DATE_FORMAT.ISO).format();
  const secondCreatedDate = moment(filteredDates[1], DATE_FORMAT.ISO)
    .set({ hour: 23, minute: 59, second: 59 })
    .format();

  return `${firstCreatedDate}${ASCII_ENCODE.TILDE}${secondCreatedDate}`;
};

const getIssueKeyFilter = selectedFilter => {
  return {
    selectedFilter: `${ASCII_ENCODE.PERCENT}${selectedFilter}${ASCII_ENCODE.PERCENT}`,
    filterBy: "ticket.issueKey"
  };
};

export const getProviderKeyIdFromResponse = (relationships, providerId) => {
  let key = null,
    issueId = null;

  for (const element of relationships) {
    if (
      element["type"] === "supportRequestProvider" &&
      element["id"] === providerId
    ) {
      key = element["attributes"]["key"];
      issueId = element["attributes"]["issueId"];
    }
  }

  return { providerKey: key, providerId: issueId };
};

export const addUpdateFileToBody = (file, formData) => {
  formData.append(
    UPDATE_CASE_PARAM[file.file.type] ||
      UPDATE_CASE_PARAM[ALIASES[file.file.type]],
    file.file.originFileObj
  );
};

export const parseReviewerDataSource = source => {
  return source.map(entry => {
    return {
      name: `${entry.firstName} ${entry.lastName}`,
      userId: entry.id
    };
  });
};

export const getAllPlanningDates = surgeryPlanning => {
  if (Array.isArray(surgeryPlanning)) {
    return surgeryPlanning.map(planning => planning.lastModifiedPlanningDate);
  }
};

export const getTasksDtoInfo = response => {
  const taskInfoArray = [];

  response.data?.forEach(taskDto => {
    taskInfoArray.push({
      description: taskDto?.attributes.description,
      taskType: taskDto?.attributes.taskType,
      taskSubType: taskDto?.attributes.taskSubType,
      state: taskDto?.attributes.taskResult,
      id: taskDto?.id
    });
  });

  return taskInfoArray;
};

export const getCaseTaskSubtitle = (task, userId = null) => {
  if (
    [CASE_TASKS.INJECT.USER, CASE_TASKS.UPDATE.USER].includes(task) &&
    userId !== LIST_DEFAULT_VALUE
  ) {
    return `User ID ${userId}`;
  } else if (task === CASE_TASKS.ANNOTATION2CASE.VERIFIER) {
    return "A new case will be created in your account.";
  }
};

export const TASK_TYPE_PARAMS = {
  [CASE_TASKS.CLONE]: "clonecase",
  [CASE_TASKS.INJECT.ENGINEER]: "injecttoengineer",
  [CASE_TASKS.INJECT.VERIFIER]: "injecttoverifier",
  [CASE_TASKS.INJECT.USER]: "injecttouser",
  [CASE_TASKS.UPDATE.USER]: "updatecase",
  [CASE_TASKS.ANNOTATION2CASE.VERIFIER]: "caseannotations"
};

export const getTaskTypesByParam = param => {
  const TASK_TYPE_AND_SUBTYPE = {
    clonecase: {
      taskType: "clone"
    },
    injecttoengineer: {
      taskType: "inject",
      taskSubType: "engineer"
    },
    injecttoverifier: {
      taskType: "inject",
      taskSubType: "verifier"
    },
    injecttouser: {
      taskType: "inject",
      taskSubType: "user"
    },
    updatecase: {
      taskType: "update",
      taskSubType: "user"
    },
    caseannotations: {
      taskType: "annotation2case",
      taskSubType: "verifier"
    }
  };

  const taskType = TASK_TYPE_AND_SUBTYPE[param]?.taskType;
  const taskSubType = TASK_TYPE_AND_SUBTYPE[param]?.taskSubType;

  return { taskType, taskSubType };
};
