import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

const ActionButtons = ({ buttons, record }) => {
  const handleButtonClick = buttonAction => {
    buttonAction(record);
  };

  return (
    <div className="action-buttons-container">
      {buttons.map(button => {
        const { title, action, disabled } = button;

        const isDisabled = disabled === true || disabled?.(record);

        return (
          <Tooltip key={title} title={title} placement="bottom">
            <button.iconComponent
              className={isDisabled ? "disabled" : ""}
              onClick={() => handleButtonClick(action)}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  buttons: PropTypes.array,
  record: PropTypes.object
};
