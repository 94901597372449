import moment from "moment";
import { isEmpty } from "lodash";
import proVoyanceLogo from "../assets/provoyance-logo.png";

const base64Prefix = "data:image/jpeg;base64,";
const maxWidthStyle = "style='max-width: 650px'";
const dateFormat = "MM/DD/YYYY";

export const defaultImage =
  "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAALcAAAETCAMAAABDSmfhAAAAMFBMVEX09PTMzMzx8fHPz8/u7u7JycnY2NjT09P39/fm5ubq6urR0dHf39/W1tbt7e3c3NzOM/f9AAAB7UlEQVR4nO3a626qQBSAUS4dLmrr+79t0XoBtC3MOSnbZK2fCsnnZBwYsSgAAAAAAAAAAAAAAAAAAAAAAAAAAH7zlmPr6OJQlzn2h22z31NWdlmmZsvsQ272EL7liOdNki/77bKrYbjTR8aJH6fw6r/3LHXqLruMEzvdOSbd3WDpiYG6+/eybJeuEXG62/OKmOpl39Iw3e1tIV9UE6W7v2WnZskkD9LdtaPr4JPjHj5KkO5ifP3ePR53nCe+RneT5o1Rukf3KekhZ8gu6+mrUbqPo9ul2WTumvN3dhoepbu6ryf97Jjm8tZ+nBmlu9hd6tJhOtxdc/tE4xEP0929tWVKZd3PZkkz3ljcQ8N0n8r7/uEiP8kejXig7ie6ZraNq6/7+NDdD9n38MjdT7JvUyVcd7W7bSOeZV/Dw3W36bqiPM++5AbrrtrThef8wrfZZV2E6z7fzp7Cv5kkX8J1X+7Ch/CfssN13zcP7XfJEbt/jo3aXS3OjtW9PDtU94rsSN1rsgN1V2uydet+se7il9Ko3dV+jTjd52cNiwXqXutlu9P23btq9b8Kqn7b8S72qxaSqXa77O5fns/Pf5L7U8fs/0McN6we9G2do53/3PzXuqLKseUcAQAAAAAAAAAAAAAAAAAAAAAAAABexCfv9xRvgCHcRgAAAABJRU5ErkJggg==";

export const header = (notFirst, lastHeader) => {
  return `<div class="flex header ${notFirst ? " not-first" : ""} ${
    lastHeader ? " last-header" : ""
  }">
    <div class="header-img">
      <img src="${proVoyanceLogo}" height="50" alt="ProVoyance-logo" />
    </div>
    <div class="content-key case-date-main">
      Case report date: <span class="case-date">${parseDate()}</span>
    </div>
  </div>`;
};

const container = (watermarkOrder, notFirst, lastHeader) => {
  return `<div class="container">
    ${header(notFirst, lastHeader)}
    ${watermark(watermarkOrder)}
  </div>`;
};

export const planNameTitle = (
  planName,
  isFavorite,
  isScreenshotsPage = false
) => {
  return `<div class="plan-name-title"${
    isScreenshotsPage ? " style='position: relative; bottom: 4rem'" : ""
  }>
    <p class="plan-name-content">${planName}</p>
    ${
      isFavorite
        ? "<label style='color:#FAAD14; padding-left: 0.5rem; margin-top: 0.2rem; display:inline-block;'>&#9733;</label>"
        : ""
    }
  </div>
  <div class="plan-name-line" ${
    isScreenshotsPage ? "style='position: relative; bottom: 4rem'" : ""
  }></div>`;
};

export const patientMeasurements = (
  planName,
  isFavorite,
  options,
  firstImageParams,
  secondImageParams
) => {
  const { watermarkOrder, notFirst, lastHeader, showPlanName } = options;
  const { altContentValue, divClass, columnTitle, firstImage } =
    firstImageParams;
  const {
    secondAltContentValue,
    secondDivClass,
    secondColumnTitle,
    secondImage
  } = secondImageParams;

  return `${container(watermarkOrder, notFirst, lastHeader)}
  <div class="row" style="padding-top:-1rem">
    ${showPlanName ? planNameTitle(planName, isFavorite) : ""}
    ${imageElement(
      altContentValue,
      divClass,
      columnTitle,
      "left",
      firstImage || defaultImage
    )}
    ${imageElement(
      secondAltContentValue,
      secondDivClass,
      secondColumnTitle,
      "right",
      secondImage || defaultImage
    )}
  </div>`;
};

export const imageElement = (
  altContentValue,
  divClass,
  columnTitle,
  position,
  base64image
) => {
  return `<div class="column ${position}-column">
      ${
        columnTitle
          ? "<div class='column-content'><div class='column-content-title'>" +
            columnTitle +
            "</div>"
          : "<div class='column-content'>"
      }
      <div class=${divClass || ""}>
        <img
          width="242"
          height="242"
          src="${base64image || defaultImage}"
          alt="${altContentValue}"
        />
        <div class="content-key inscription">
          ${altContentValue}
        </div>
      </div>
    </div>
  </div>`;
};

export const watermark = order => {
  return `<div class="watermark ${order}-watermark">
      &#9432; Not for clinical use
    </div>`;
};

export const parseDate = (dateToParse, dateFormatVal = dateFormat) => {
  return moment(dateToParse).format(dateFormatVal);
};

export const parseIsoStringToDate = lastModifiedPlanningDate => {
  const isoStringValue = lastModifiedPlanningDate;

  if (!isoStringValue) {
    return "-";
  }

  return parseDate(isoStringValue, `${dateFormat} hh:mma`);
};

const getGlenoidValue = (operationSideVal, glenoidVersion) => {
  if (operationSideVal === "RIGHT") {
    return glenoidVersion > 0 ? "Glenoid anteversion" : "Glenoid retroversion";
  } else {
    return glenoidVersion > 0 ? "Glenoid retroversion" : "Glenoid anteversion";
  }
};

export const parseGlenoidVersion = (glenoidVersion, operationSideVal) => {
  let res = "-";

  if (operationSideVal && glenoidVersion === 0) {
    res = "Glenoid version";
  } else if (operationSideVal) {
    res = getGlenoidValue(operationSideVal, glenoidVersion);
  }

  return res;
};

export const existSurgeryMeasurements = reportData => {
  return (
    reportData.surgeryMeasurements &&
    reportData.surgeryMeasurements.patientProfileData
  );
};

export const existSurgeryPlanning = (reportData, planIdx) => {
  return (
    reportData.surgeryPlannings && reportData.surgeryPlannings[planIdx].data
  );
};

export const existCustomMeasurements = (reportData, planIdx) => {
  return (
    reportData.surgeryPlannings[planIdx].data.CustomMeasurements &&
    !isEmpty(reportData.surgeryPlannings[planIdx].data.CustomMeasurements)
  );
};

export const existUserScreenshots = reportData => {
  return (
    reportData.planningUserScreenshots &&
    !isEmpty(reportData.planningUserScreenshots)
  );
};

export const getPlanningScreenshot = (
  planningScreenshots,
  imageType,
  planIdx
) => {
  if (!planningScreenshots) {
    return null;
  }
  let screenshotRes = null;
  planningScreenshots.forEach(screenshot => {
    if (screenshot.imageType === imageType && screenshot.idx === planIdx) {
      screenshotRes = base64Prefix + screenshot.binaryPlanner3D;
    }
  });
  return screenshotRes;
};

const parseToDecimals = (numberToFix, decimals = 2) => {
  return numberToFix.toFixed(decimals).replace(/\.00$/, "");
};

export const getCustomMeasurements = customMeasurements => {
  const ellipsisStyle =
    'style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 11rem;"';
  let result = "",
    index = 0;

  for (index; index < customMeasurements.length; index = index + 3) {
    const element = customMeasurements[index],
      nextElement = customMeasurements[index + 1],
      secondNextElement = customMeasurements[index + 2];
    result += `<div class="row">
      <div class="column" style="width: 33.3%;">
        <div class="content-key inscription" ${ellipsisStyle}>
          ${element.PointA} -
        </div>
        <div class="content-key inscription" ${ellipsisStyle}>
          ${element.PointB}
        </div>
        <div class="content-value inscription">
          ${parseToDecimals(element.Distance * 100, 1)}mm
        </div>
      </div>
      ${
        nextElement
          ? "<div class='column' style='width: 33.3%;'> <div class='content-key inscription' " +
            ellipsisStyle +
            ">  " +
            nextElement.PointA +
            " - </div>" +
            "<div class='content-key inscription' " +
            ellipsisStyle +
            "> " +
            nextElement.PointB +
            " </div>" +
            "<div class='content-value inscription'>" +
            parseToDecimals(nextElement.Distance * 100, 1) +
            "mm </div> </div>"
          : ""
      }
      ${
        nextElement && secondNextElement
          ? "<div class='column'><div class='content-key inscription'" +
            ellipsisStyle +
            ">" +
            secondNextElement.PointA +
            " - </div><div class='content-key inscription'" +
            ellipsisStyle +
            ">" +
            secondNextElement.PointB +
            "</div>" +
            "<div class='content-value inscription'>" +
            parseToDecimals(secondNextElement.Distance * 100, 1) +
            "mm</div></div>"
          : ""
      }</div>`;
  }

  return result;
};

export const getUserPlanningScreenshots = (
  reportData,
  planIdx,
  showPlanName
) => {
  const screenshots = reportData.planningUserScreenshots.filter(
    s => s.planIdx === planIdx
  );
  let result = "";
  let index = 0;
  let planName;
  let isFavorite;
  if (showPlanName) {
    const planData = reportData.surgeryPlannings[planIdx].data.PlanData;
    planName = planData.name;
    isFavorite = planData.isFavorite;
  }

  for (index; index < screenshots.length; index += 2) {
    const screenshot = screenshots[index];
    const nextScreenshot = screenshots[index + 1];
    result += `${header(false, true)}
               ${watermark("fourth")}
               ${showPlanName ? planNameTitle(planName, isFavorite, true) : ""}
               <div style="padding-left: 4rem;padding-bottom: 2rem;margin-top: -3rem">
                ${
                  index === 0
                    ? "<div style='font-size: 1rem; font-weight: bold; color: black'>Screenshots</div>"
                    : ""
                }
                <img
                  width="650px"
                  height="360px"
                  src="${base64Prefix + screenshot.binaryPlanner3D}"
                />
                <div class="content-key inscription" ${maxWidthStyle}>
                Screenshot date: ${parseDate(screenshot.screenshotDate)}. ${
      screenshot.comment || ""
    }
                </div>
               </div>
               ${
                 nextScreenshot
                   ? "<div style='padding-left: 4rem;'><img width='650px' height='360px' src='" +
                     base64Prefix +
                     nextScreenshot.binaryPlanner3D +
                     "' />" +
                     "<div class='content-key inscription' " +
                     maxWidthStyle +
                     "> Screenshot date: " +
                     parseDate(screenshot.screenshotDate) +
                     ". " +
                     (nextScreenshot.comment || "") +
                     "</div></div>"
                   : ""
               }`;
  }
  return result;
};

export const getImplantInfo = (reportData, caseSummary, planIdx) => {
  const implantType = existSurgeryPlanning(reportData, planIdx)
    ? caseSummary[planIdx].implantType
    : "-";

  return reportData.surgeryPlannings[planIdx].data.Procedure === 0
    ? `<div class="column" style="padding-left: 6.4rem;">
        <div class="content-key inscription">Implant</div>
        <div class="content-value inscription">${implantType}</div>
      </div>`
    : "<div></div>";
};

export const planningScreenshotsTypes = {
  SCAPULA_SAGITTAL_PLANE_WITHOUT_IMPLANT:
    "SCAPULA_SAGITTAL_PLANE_WITHOUT_IMPLANT",
  SCAPULA_AND_GLENOID_PLANES: "SCAPULA_AND_GLENOID_PLANES",
  AXIAL_SUBLUXATION_VIEW: "AXIAL_SUBLUXATION_VIEW",
  SCAPULA_SAGITTAL_PLANE_WITH_IMPLANT: "SCAPULA_SAGITTAL_PLANE_WITH_IMPLANT",
  AXIAL_VIEW_WITH_IMPLANT: "AXIAL_VIEW_WITH_IMPLANT",
  CORONAL_VIEW_PLANE_WITH_IMPLANT: "CORONAL_VIEW_PLANE_WITH_IMPLANT",
  SCAPULA_SAGITTAL_PLANE_WITH_MEASUREMENTS:
    "SCAPULA_SAGITTAL_PLANE_WITH_MEASUREMENTS",
  OTHER: "OTHER"
};

export const pocketDepthValue = depthValue => {
  return depthValue > 0
    ? `${(Math.round(depthValue * 10000) / 10).toFixed(1)}mm`
    : "none";
};

export const getReportGenerationTimestamp = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  return `${date.getFullYear()}${month}${day}_${date
    .toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false
    })
    .replace(":", "_")}`;
};
