import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import { CloseOutlined } from "@ant-design/icons";
import { BO_LOCATION_PATH, InfoCard } from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import NewsForm from "./NewsForm";
import NewsError from "./NewsError";
import "./NewsEdit.less";

const { Content } = Layout;

const NewsCreate = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <BoHeader title={"Create news"} />
      <Content>
        <div className="content-container">
          <Row align="middle" justify="center" type={"flex"}>
            <Col span={12}>
              <InfoCard>
                <ErrorBoundary fallback={<NewsError />}>
                  <div className="btn-containter">
                    <CloseOutlined
                      onClick={() => history.push(BO_LOCATION_PATH.NEWS)}
                    />
                  </div>
                  <NewsForm />
                </ErrorBoundary>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default React.memo(NewsCreate);
