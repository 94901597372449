import { useEffect, useRef } from "react";
import { REQUEST_STATUS } from "gsi-ui-components";
import { getAllPlanningDates } from "../../../helpers/ticketsHelper";
import { generateReport } from "../../../helpers/reportGeneration";
import { parsePatientValues } from "../helpers/caseListHelper";

const useViewReport = (
  caseInfo,
  clearCaseInfo,
  planningInfo,
  getPlanningInfo,
  clearPlanningInfo,
  implantsInfo
) => {
  const viewReportRequested = useRef(false);

  useEffect(() => {
    viewReportRequested.current &&
      caseInfo.status === REQUEST_STATUS.SUCCESS &&
      getPlanningInfo(
        caseInfo.data.surgeryPlannings?.references,
        caseInfo.data.planner3DImages?.references
      );
  }, [caseInfo, getPlanningInfo, implantsInfo]);

  useEffect(() => {
    if (
      viewReportRequested.current &&
      planningInfo.status === REQUEST_STATUS.SUCCESS
    ) {
      const patientValues = parsePatientValues(caseInfo);
      const lastModifiedPlanningDates = getAllPlanningDates(planningInfo.data);

      generateReport(
        caseInfo,
        planningInfo,
        patientValues,
        lastModifiedPlanningDates,
        implantsInfo
      );
      clearCaseInfo();
      clearPlanningInfo();
      viewReportRequested.current = false;
    }
  }, [caseInfo, clearCaseInfo, planningInfo, clearPlanningInfo, implantsInfo]);

  return viewReportRequested;
};

export default useViewReport;
