import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const NewsError = () => {
  return (
    <div className="error-container">
      <ExclamationCircleOutlined />
      <p>
        An error occured while loading the news.
        <br />
        Please check the address and try again.
      </p>
    </div>
  );
};

export default React.memo(NewsError);
