import { REQUEST_STATUS } from "gsi-ui-components";
import { TICKET_UPDATE_TASKS } from "../helpers/types";

const initialState = { status: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case TICKET_UPDATE_TASKS.LOADING:
      return { ...state, status: REQUEST_STATUS.LOADING };
    case TICKET_UPDATE_TASKS.SUCCESS:
      return { ...state, status: REQUEST_STATUS.SUCCESS };
    case TICKET_UPDATE_TASKS.ERROR:
      return { ...state, status: REQUEST_STATUS.ERROR };
    case TICKET_UPDATE_TASKS.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
