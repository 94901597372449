import { NOTIFICATIONS } from "../../helpers/types";
import { filterNotificationByIdUrl } from "../../helpers/URLHelper";

export const getNotifications = filters => async dispatch => {
  try {
    const [
      { sendGetRequest },
      { getNotificationsDtoInfo },
      { getNotificationsUrl }
    ] = await Promise.all([
      import(`../../helpers/URLHelper`),
      import(`../../helpers/notificationsHelper`),
      import(`gsi-ui-components`)
    ]);
    dispatch({
      type: NOTIFICATIONS.LOADING
    });
    const url = filters
      ? `${getNotificationsUrl()}?${filters}`
      : getNotificationsUrl();
    const response = await sendGetRequest(url);
    const notificationsData = getNotificationsDtoInfo(response.data);
    const totalRecords = response.data?.meta?.page?.totalRecords;

    dispatch({
      type: NOTIFICATIONS.SUCCESS,
      payload: { notificationsData, totalRecords }
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: NOTIFICATIONS.ERROR
    });
  }
};

export const getNotificationById = id => async dispatch => {
  try {
    const [{ sendGetRequest }, { getNotificationsDtoInfo }] = await Promise.all(
      [
        import(`../../helpers/URLHelper`),
        import(`../../helpers/notificationsHelper`)
      ]
    );
    dispatch({
      type: NOTIFICATIONS.LOADING
    });
    const url = filterNotificationByIdUrl(id);
    const response = await sendGetRequest(url);

    const notificationsData = getNotificationsDtoInfo({
      data: response.data?.data
    });
    dispatch({
      type: NOTIFICATIONS.SUCCESS,
      payload: { notificationsData, totalRecords: 1 }
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: NOTIFICATIONS.ERROR
    });
  }
};

export const clearNotifications = () => async dispatch => {
  dispatch({
    type: NOTIFICATIONS.CLEAR
  });
};
