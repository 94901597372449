import { requestStatus } from "gsi-ui-components";
import { CASE_TASK } from "../helpers/types";

const initialState = {
  pipelineName: null,
  task: null,
  ticketId: null,
  caseId: null,
  url: null,
  requestStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASE_TASK.LOADING:
      return { ...state, requestStatus: requestStatus.LOADING };
    case CASE_TASK.SUCCESS:
      return {
        ...state,
        ...action.payload,
        requestStatus: requestStatus.SUCCESS
      };
    case CASE_TASK.SET:
      return { ...state, ...action.payload };
    case CASE_TASK.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
