import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import PropTypes from "prop-types";
import "./BreadcrumbContainer.less";

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join("/")}`}>{route.breadcrumbName}</Link>
  );
};

const BreadcrumbContainer = routes => {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb itemRender={itemRender} routes={routes} />
    </div>
  );
};

BreadcrumbContainer.propTypes = {
  routes: PropTypes.array
};

export default BreadcrumbContainer;
