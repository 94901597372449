import {
  bankersRound,
  getBasePlateAngle,
  getBasePlateDiameter,
  getBasePlateStem,
  getBaseplateThickness,
  getCentralScrewLength,
  getGlenoidDiameter,
  getGlenoidThickness,
  getGlenosphereDiameter,
  getGlenosphereLabel,
  getGlenosphereOffset,
  getGlenosphereRotation,
  getImplantType,
  getMedLat,
  getNoRimInset,
  getNoSeating,
  getSelectedBaseplate,
  getSelectedCentralScrew,
  getSelectedGlenosphere,
  getSelectedScapularAnatomicImplant,
  parseGlenoidPatientAnatomy,
  parseGlenosphereType,
  parseHumerusSubluxation,
  parseInclination,
  parseMedLat,
  parseRim,
  parseRotation,
  parseSeating,
  parseVersion
} from "gsi-ui-components";

export const getPlanningSummary = (
  planning,
  surgeryMeasurements,
  operationSide,
  implantsInfo
) => {
  const planningData = planning.data;
  const scapularImplantData = planningData.ImplantData;

  let scapularPlanningSummary = {
    backsideSupport: parseSeating(scapularImplantData.Seating),
    faceInclination: Math.abs(
      bankersRound(scapularImplantData.SecondaryInclination)
    ),
    faceVersion: Math.abs(bankersRound(scapularImplantData.SecondaryVersion)),
    glenoidInclination: parseGlenoidPatientAnatomy(
      surgeryMeasurements.patientProfileData.GlenoidInclination,
      planningData.PlanData.idx
    ),
    glenoidVersion: parseGlenoidPatientAnatomy(
      surgeryMeasurements.patientProfileData.GlenoidVersion,
      planningData.PlanData.idx
    ),
    humerusSubluxation: parseHumerusSubluxation(
      surgeryMeasurements.patientProfileData.HumerusSubluxation,
      planningData.PlanData.idx
    ),
    inclinationType: parseInclination(
      planningData.Procedure === 1
        ? scapularImplantData.SecondaryInclination
        : scapularImplantData.Inclination
    ),
    medializationLateralization: parseMedLat(
      scapularImplantData.Lateralization
    ),
    medializationLateralizationType: getMedLat(
      scapularImplantData.Lateralization
    ),
    noRimInset: getNoRimInset(
      scapularImplantData.RimFullSeating,
      scapularImplantData.RimPartialSeating
    ),
    noSeating: getNoSeating(scapularImplantData.Seating),
    rimMajor: parseRim(scapularImplantData.RimFullSeating),
    rimMinor: parseRim(scapularImplantData.RimPartialSeating),
    rotation: parseRotation(scapularImplantData.Rotation),
    secondaryInclinationType: parseInclination(
      scapularImplantData.SecondaryInclination
    ),
    secondaryVersionType: parseVersion(
      bankersRound(scapularImplantData.SecondaryVersion),
      operationSide
    ),
    version: Math.abs(
      bankersRound(
        planningData.Procedure === 1
          ? scapularImplantData.SecondaryVersion
          : scapularImplantData.Version
      )
    ),
    versionType: parseVersion(
      bankersRound(
        planningData.Procedure === 1
          ? scapularImplantData.SecondaryVersion
          : scapularImplantData.Version
      ),
      operationSide
    ),
    pocketInclination: Math.abs(
      bankersRound(
        planningData.Procedure === 1
          ? scapularImplantData.SecondaryInclination
          : scapularImplantData.Inclination
      )
    )
  };

  if (planningData.Procedure === 0) {
    const selectedScapularAnatomicImplant = getSelectedScapularAnatomicImplant(
      scapularImplantData.ImplantCode,
      implantsInfo
    );

    scapularPlanningSummary.glenoidDiameter = getGlenoidDiameter(
      selectedScapularAnatomicImplant,
      implantsInfo
    );
    scapularPlanningSummary.glenoidThickness = getGlenoidThickness(
      selectedScapularAnatomicImplant,
      implantsInfo
    );
    scapularPlanningSummary.implantType = getImplantType(
      selectedScapularAnatomicImplant,
      implantsInfo
    );
  } else {
    const selectedBaseplate = getSelectedBaseplate(
      scapularImplantData.ImplantCode,
      implantsInfo
    );

    const selectedCentralScrew = getSelectedCentralScrew(
      scapularImplantData.CentralScrewCode,
      implantsInfo
    );
    const selectedGlenosphere = getSelectedGlenosphere(
      scapularImplantData.GlenosphereCode,
      implantsInfo
    );

    scapularPlanningSummary.basePlateAngle = getBasePlateAngle(
      selectedBaseplate,
      implantsInfo
    );
    scapularPlanningSummary.basePlateDiameter = getBasePlateDiameter(
      selectedBaseplate,
      implantsInfo
    );
    scapularPlanningSummary.basePlateStem = getBasePlateStem(
      selectedBaseplate,
      implantsInfo
    );
    scapularPlanningSummary.basePlateThickness =
      scapularImplantData.ReverseOptsData.BaseplateStem === 0
        ? getBaseplateThickness(selectedBaseplate, implantsInfo)
        : "";
    scapularPlanningSummary.centralScrew = getCentralScrewLength(
      selectedCentralScrew,
      implantsInfo
    );
    scapularPlanningSummary.glenosphereDiameter = getGlenosphereDiameter(
      selectedGlenosphere,
      implantsInfo
    );
    scapularPlanningSummary.glenosphereRotation = getGlenosphereRotation(
      parseRotation(scapularImplantData.ReverseOptsData.GlenosphereRotation)
    );
    scapularPlanningSummary.glenosphereOffset = getGlenosphereOffset(
      selectedGlenosphere,
      implantsInfo
    );
    scapularPlanningSummary.glenosphereLabel = getGlenosphereLabel(
      scapularImplantData.ReverseOptsData.GlenosphereType
    );
    scapularPlanningSummary.glenosphereType =
      parseGlenosphereType(selectedGlenosphere);
  }

  return scapularPlanningSummary;
};
