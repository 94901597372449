import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import BoHeader from "../layout/BoHeader";
import TicketList from "./TicketList";

const { Content } = Layout;

const ResquestSupportContainer = history => {
  return (
    <React.Fragment>
      <BoHeader title="Request Support" />
      <Content>
        <TicketList history={history} />
      </Content>
    </React.Fragment>
  );
};

ResquestSupportContainer.propTypes = {
  history: PropTypes.object
};

export default React.memo(ResquestSupportContainer);
