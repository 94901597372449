import { NOTIFICATIONS_EDIT } from "../../helpers/types";

export const submitNotification =
  (data, id = null) =>
  async dispatch => {
    try {
      const [
        { sendPostRequest, sendPatchRequest },
        { buildNotificationReqBody },
        { BODY_PARSER, JSON_CONTENT_TYPE, getNotificationsUrl }
      ] = await Promise.all([
        import(`../../helpers/URLHelper`),
        import(`../../helpers/notificationsHelper`),
        import(`gsi-ui-components`)
      ]);
      dispatch({
        type: NOTIFICATIONS_EDIT.LOADING
      });
      const body = buildNotificationReqBody(data);
      if (id) {
        await sendPatchRequest(
          `${getNotificationsUrl()}/${id}`,
          BODY_PARSER.JSON,
          { data: { ...body.data, id } },
          JSON_CONTENT_TYPE
        );
      } else {
        await sendPostRequest(
          getNotificationsUrl(),
          BODY_PARSER.JSON,
          body,
          JSON_CONTENT_TYPE
        );
      }
      dispatch({
        type: NOTIFICATIONS_EDIT.SUCCESS
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: NOTIFICATIONS_EDIT.ERROR
      });
    }
  };

export const clearSubmitNotificationStatus = () => async dispatch => {
  dispatch({
    type: NOTIFICATIONS_EDIT.CLEAR
  });
};
