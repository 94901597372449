import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import {
  getBoBackendUrl,
  getBoRefreshTokenUrl,
  BO_AUTH_COOKIE_NAME,
  BO_LOCATION_PATH,
  KEYCLOAK_USER_ROLES
} from "gsi-ui-components";
import Home from "../home/Home";
import SiderMenu from "../layout/SiderMenu";
import RequestSupportContainer from "../requestSupport/RequestSupportContainer";
import RequestSupportCases from "../requestSupport/RequestSupportCases";
import CaseTask from "../requestSupport/CaseTask";
import NotFound from "../notFound/NotFound";
import UserContainer from "../users/UserContainer";
import UsersList from "../users/UsersList";
import AnalyticsContainer from "../analytics/AnalyticsContainer";
import ResultPage from "../requestSupport/requestDetails/ResultPage";
import CaseDetail from "../caseDetail/CaseDetail";
import CaseList from "../caseList/CaseList";
import userService from "../../helpers/userService";
import { dispatchUserRoles } from "../../actions/user";
import { signOut } from "../../actions/auth";
import NewsList from "../news/NewsList";
import NewsCreate from "../news/NewsCreate";
import NewsEdit from "../news/NewsEdit";
import NotificationsList from "../notifications/NotificationsList";
import NotificationsCreate from "../notifications/NotificationsCreate";
import NotificationsEdit from "../notifications/NotificationsEdit";

const authBackoffice = userService.getCookie(BO_AUTH_COOKIE_NAME);
let refreshTimer;

const Secured = ({ dispatchUserRoles, userRoles, signOut }) => {
  useEffect(() => {
    if (authBackoffice) {
      dispatchUserRoles();
      updateRefreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRefreshToken = () => {
    clearTimeout(refreshTimer);
    refreshTimer = setTimeout(async () => {
      try {
        await userService.updateToken(getBoRefreshTokenUrl());
        updateRefreshToken();
      } catch {
        signOut(createBrowserHistory());
      }
    }, userService.getRefreshTokenExpiration());
  };

  const hasBackofficeRole = userRoles.includes(KEYCLOAK_USER_ROLES.BACKOFFICE);
  const hasSupportRole = userRoles.includes(
    KEYCLOAK_USER_ROLES.SUPPORT_ENGINEER
  );
  const hasSalesRepRole = userRoles.includes(KEYCLOAK_USER_ROLES.SALES_REP);
  const currentPath = useLocation();

  const getPath = () => {
    if (userService.isRefreshTokenExpired()) {
      signOut(createBrowserHistory());
    }

    return !isEmpty(userRoles) ? (
      <React.Fragment>
        <SiderMenu />
        <Switch>
          <Route exact path={BO_LOCATION_PATH.ROOT} component={Home} />
          {hasBackofficeRole && (
            <Route exact path={BO_LOCATION_PATH.USERS} component={UsersList} />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.ADD_USER}
              component={UserContainer}
            />
          )}
          {hasSupportRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.REQUEST_SUPPORT}
              component={RequestSupportContainer}
            />
          )}
          {hasSupportRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.REQUEST_SUPPORT_CASES}
              component={RequestSupportCases}
            />
          )}
          {hasSupportRole && (
            <Route
              exact
              path={`${BO_LOCATION_PATH.CASE_TASK}/:taskTypeParam/:ticketIdParam`}
              component={CaseTask}
            />
          )}
          {hasSupportRole && (
            <Route
              exact
              path={`${BO_LOCATION_PATH.CASE_TASK}/:taskTypeParam/:ticketIdParam/finish`}
              component={ResultPage}
            />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.ANALYTICS}
              component={AnalyticsContainer}
            />
          )}
          {hasSupportRole && (
            <Route
              path={`${BO_LOCATION_PATH.SURGERY_CASES}/:id`}
              component={CaseDetail}
            />
          )}
          {hasSalesRepRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.CASE_LIST}
              component={CaseList}
            />
          )}
          {hasBackofficeRole && (
            <Route exact path={BO_LOCATION_PATH.NEWS} component={NewsList} />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.NEWS_ADD}
              component={NewsCreate}
            />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={`${BO_LOCATION_PATH.NEWS_EDIT}/:newsIdParam/`}
              component={NewsEdit}
            />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.NOTIFICATIONS}
              component={NotificationsList}
            />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={BO_LOCATION_PATH.NOTIFICATIONS_ADD}
              component={NotificationsCreate}
            />
          )}
          {hasBackofficeRole && (
            <Route
              exact
              path={`${BO_LOCATION_PATH.NOTIFICATIONS_EDIT}/:notificationIdParam/`}
              component={NotificationsEdit}
            />
          )}
          <Route
            render={() => <Redirect to={{ pathname: BO_LOCATION_PATH.ROOT }} />}
          />
        </Switch>
      </React.Fragment>
    ) : (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: currentPath.pathname,
              search: currentPath.search
            }}
          />
        )}
      />
    );
  };

  return authBackoffice ? (
    getPath()
  ) : (
    <Switch>
      <Route exact path={BO_LOCATION_PATH.ACCESS_DENIED} component={NotFound} />
      <Route
        exact
        component={() => {
          window.location.href = getBoBackendUrl();
          return null;
        }}
      />
    </Switch>
  );
};

Secured.propTypes = {
  dispatchUserRoles: PropTypes.func,
  userRoles: PropTypes.array,
  signOut: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userRoles: state.user.userRoles
  };
};

export default connect(mapStateToProps, { dispatchUserRoles, signOut })(
  React.memo(Secured)
);
