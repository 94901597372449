import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Layout, Row, Spin } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { ErrorBoundary } from "react-error-boundary";
import { BO_LOCATION_PATH, InfoCard, REQUEST_STATUS } from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import NotificationsForm from "./NotificationsForm";
import NotificationsError from "./NotificationsError";
import { getNotificationById } from "../../actions/notifications/notifications";
import "./NotificationsEdit.less";

const { Content } = Layout;

const NotificationsEdit = ({ notifications, status, getNotificationById }) => {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    params.notificationIdParam &&
      getNotificationById(params.notificationIdParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getDisplayScreen = () => {
    switch (status) {
      case REQUEST_STATUS.ERROR:
        return <NotificationsError />;
      case REQUEST_STATUS.SUCCESS:
        return (
          <NotificationsForm notificationsData={notifications[0]} isEditing />
        );
      case REQUEST_STATUS.LOADING:
      default:
        return <Spin className={`spinner`} indicator={<LoadingOutlined />} />;
    }
  };

  return (
    <React.Fragment>
      <BoHeader title={"Edit release notification"} />
      <Content>
        <div className="content-container">
          <Row align="middle" justify="center" type={"flex"}>
            <Col span={12}>
              <InfoCard>
                <ErrorBoundary fallback={<NotificationsError />}>
                  <div className="btn-containter">
                    <CloseOutlined
                      onClick={() =>
                        history.push(BO_LOCATION_PATH.NOTIFICATIONS)
                      }
                    />
                  </div>
                  {getDisplayScreen()}
                </ErrorBoundary>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </React.Fragment>
  );
};

NotificationsEdit.propTypes = {
  getNotificationById: PropTypes.func,
  notifications: PropTypes.array,
  status: PropTypes.string
};

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notificationsData,
    status: state.notifications.status
  };
};

export default connect(mapStateToProps, { getNotificationById })(
  NotificationsEdit
);
