import { omit } from "lodash";
import {
  userService,
  parseActionError,
  getUsersUrl,
  getRoleMappingsUrl,
  getRolesUrl,
  getSendEmailUrl,
  getUserCountUrl,
  getUserListUrl,
  BODY_PARSER,
  JSON_CONTENT_TYPE,
  BO_LOCATION_PATH,
  USER_ACTION_RELATED_MESSAGE,
  RESET_PASSWORD_MESSAGE,
  KEYCLOAK_REQUIRED_ACTIONS,
  USER_ROLES,
  VERIFY_EMAIL
} from "gsi-ui-components";
import {
  sendGetRequest,
  sendPostRequest,
  sendPutRequest
} from "../helpers/URLHelper";

export const createUser = userInfo => async dispatch => {
  const { USER_ACTION_RELATED } = await import(`../helpers/types`);
  try {
    dispatch({
      type: USER_ACTION_RELATED.LOADING
    });

    const body = {
      ...omit(userInfo, ["role"]),
      enabled: true,
      requiredActions: [KEYCLOAK_REQUIRED_ACTIONS.UPDATE_PASSWORD]
    };
    const [response, roleInfo] = await Promise.all([
      sendPostRequest(getUsersUrl(), BODY_PARSER.JSON, body, JSON_CONTENT_TYPE),
      sendGetRequest(getRolesUrl(userInfo.role))
    ]);
    const userId = response.headers?.location
        ?.split(BO_LOCATION_PATH.ROOT)
        ?.pop(),
      roleId = roleInfo?.data?.id;
    if (!userId) {
      throw new Error("User creation request failed. Cannot retrieve user ID.");
    }
    if (!roleId) {
      throw new Error(
        `User creation request failed. Cannot retrieve '${userInfo.role}' role ID.`
      );
    }

    const roleBody = [
      {
        id: roleId,
        name: USER_ROLES.SURGEON,
        composite: false,
        clientRole: false,
        containerId: process.env.REACT_APP_KEYCLOAK_REALM
      }
    ];
    await Promise.all([
      sendPostRequest(
        getRoleMappingsUrl(userId),
        BODY_PARSER.JSON,
        roleBody,
        JSON_CONTENT_TYPE
      ),
      sendPutRequest(
        getSendEmailUrl(userId),
        BODY_PARSER.JSON,
        [VERIFY_EMAIL],
        JSON_CONTENT_TYPE
      )
    ]);

    dispatch({
      type: USER_ACTION_RELATED.SUCCESS
    });
  } catch (error) {
    parseActionError(
      error,
      dispatch,
      USER_ACTION_RELATED,
      USER_ACTION_RELATED_MESSAGE
    );
  }
};

export const clearUserActionStatus = () => async dispatch => {
  const { USER_ACTION_RELATED } = await import(`../helpers/types`);
  dispatch({ type: USER_ACTION_RELATED.CLEAR });
};

export const updateUser = userInfo => async (dispatch, getState) => {
  const { USER_ACTION_RELATED } = await import(`../helpers/types`);
  try {
    dispatch({ type: USER_ACTION_RELATED.LOADING });

    const userId = getState().user.selectedUserId;

    await sendPutRequest(
      `${getUsersUrl()}/${userId}`,
      BODY_PARSER.JSON,
      { ...omit(userInfo, ["role"]) },
      JSON_CONTENT_TYPE
    );
    dispatch({ type: USER_ACTION_RELATED.SUCCESS });
  } catch (error) {
    parseActionError(
      error,
      dispatch,
      USER_ACTION_RELATED,
      USER_ACTION_RELATED_MESSAGE
    );
  }
};

export const resetPassword = () => async (dispatch, getState) => {
  const { RESET_PASSWORD } = await import(`../helpers/types`);
  try {
    dispatch({ type: RESET_PASSWORD.LOADING });

    const userId = getState().user.selectedUserId;

    await Promise.all([
      sendPutRequest(
        `${getUsersUrl()}/${userId}`,
        BODY_PARSER.JSON,
        {
          requiredActions: [KEYCLOAK_REQUIRED_ACTIONS.UPDATE_PASSWORD]
        },
        JSON_CONTENT_TYPE
      ),
      sendPutRequest(
        getSendEmailUrl(userId),
        BODY_PARSER.JSON,
        [KEYCLOAK_REQUIRED_ACTIONS.UPDATE_PASSWORD],
        JSON_CONTENT_TYPE
      )
    ]);
    dispatch({ type: RESET_PASSWORD.SUCCESS });
  } catch (error) {
    parseActionError(error, dispatch, RESET_PASSWORD, RESET_PASSWORD_MESSAGE);
  }
};

export const clearPasswordResetStatus = () => async dispatch => {
  const { RESET_PASSWORD } = await import(`../helpers/types`);
  dispatch({ type: RESET_PASSWORD.CLEAR });
};

export const resetUserInfo = () => async dispatch => {
  const { USER_ACTION_RELATED } = await import(`../helpers/types`);
  dispatch({ type: USER_ACTION_RELATED.RESET });
};

const getUserCount = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await sendGetRequest(getUserCountUrl());
    return response.data;
    // eslint-disable-next-line sonarjs/no-useless-catch
  } catch (error) {
    return null;
  }
};

export const listUsers = role => async dispatch => {
  const { USERS_LIST } = await import(`../helpers/types`);
  try {
    dispatch({
      type: USERS_LIST.LOADING
    });
    const userCount = await getUserCount();
    const response = await sendGetRequest(getUserListUrl(role, userCount));
    dispatch({
      type: USERS_LIST.SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: USERS_LIST.ERROR
    });
  }
};

export const selectUser =
  (selectedUserInfo, selectedUserId) => async dispatch => {
    const { SELECT_USER } = await import(`../helpers/types`);
    dispatch({
      type: SELECT_USER,
      payload: {
        isEditing: true,
        selectedUserId,
        selectedUser: selectedUserInfo
      }
    });
  };

export const dispatchUserRoles = () => async dispatch => {
  const { KEYCLOAK_USER_ROLES_ACTION } = await import(`../helpers/types`);

  dispatch({
    type: KEYCLOAK_USER_ROLES_ACTION,
    payload: userService.getUserRoles()
  });
};
