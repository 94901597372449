import React from "react";
import { connect } from "react-redux";
import { Col, Layout, Row } from "antd";
import { InfoCard } from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import "./AnalyticsContainer.less";

const { Content } = Layout;

const AnalyticsContainer = () => {
  return (
    <Layout>
      <BoHeader title="Analytics" />
      <Content>
        <div className="analytics-container">
          <Row gutter={16} type="flex" justify="center">
            <Col span={12}>
              <InfoCard title="Cases">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://lookerstudio.google.com/u/0/reporting/745d981b-90f3-41a0-83f8-5b61b05bc9a6"
                    >
                      SI Preview User Cases [prod]
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://lookerstudio.google.com/u/0/reporting/1926d26a-84fb-434b-9d4d-ca115b242682"
                    >
                      SI Preview User Cases [stage]
                    </a>
                  </li>
                </ul>
              </InfoCard>
            </Col>
            <Col span={12}>
              <InfoCard title="Sessions">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://lookerstudio.google.com/u/0/reporting/159b11fd-5b49-447d-ab08-9cb089d70f9e"
                    >
                      DHR (Design History Record)[prod]
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://lookerstudio.google.com/u/0/reporting/55e0806a-58eb-4741-be9b-a9e72d6db960"
                    >
                      DHR (Design History Record)[stage]
                    </a>
                  </li>
                </ul>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

AnalyticsContainer.propTypes = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, null)(AnalyticsContainer);
