import { REQUEST_STATUS } from "gsi-ui-components";
import { CLONE_CASE } from "../helpers/types";

const initialState = {
  cloneCaseStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLONE_CASE.LOADING:
      return { ...state, cloneCaseStatus: REQUEST_STATUS.LOADING };
    case CLONE_CASE.SUCCESS:
      return { ...state, cloneCaseStatus: REQUEST_STATUS.SUCCESS };
    case CLONE_CASE.ERROR:
      return { ...state, cloneCaseStatus: REQUEST_STATUS.ERROR };
    case CLONE_CASE.CLEAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
