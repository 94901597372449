import {
  DATE_FORMAT,
  NOTIFICATIONS_ATTR,
  PAGINATION_SIZE,
  SORT_DIRECTIONS
} from "gsi-ui-components";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { getPaginationParam, getSortingParam } from "./URLHelper";

export const getNotificationsDtoInfo = response => {
  const notificationsInfoArray = [];

  response.data?.forEach(notification => {
    const attributes = notification?.attributes;
    notificationsInfoArray.push({
      id: notification?.id,
      createdOn: attributes?.createdOn,
      releaseDate: attributes?.releaseDate,
      version: attributes?.version,
      changeLog: attributes?.changeLog
    });
  });

  return notificationsInfoArray;
};

const RELEASE_DATE_KEY = NOTIFICATIONS_ATTR.RELEASE_DATE;
const DEFAULT_SORT_KEY = "createdOn";

export const getFilterDateParam = (key, filters) => {
  try {
    const filterDates = filters && filters[key];
    const dates = filterDates && filterDates[0].split("-");

    if (key && !isEmpty(dates)) {
      return `filter[release]=${key}>=${dayjs(dates[0]).format(
        DATE_FORMAT.bigEndian
      )}&filter[release]=${key}<=${dayjs(dates[1]).format(
        DATE_FORMAT.bigEndian
      )}`;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getParams = (
  page = 1,
  pageSize = PAGINATION_SIZE,
  sortKey = DEFAULT_SORT_KEY,
  sortDirection = SORT_DIRECTIONS.DESCEND,
  filters = null
) => {
  const paginationParams = getPaginationParam(page, pageSize);
  const sortingParams = getSortingParam(sortKey, sortDirection);
  const filterParams = getFilterDateParam(RELEASE_DATE_KEY, filters);

  return [paginationParams, sortingParams, filterParams]
    .filter(e => e && e.length > 0)
    .join("&");
};

export const buildNotificationReqBody = data => {
  return {
    data: {
      attributes: {
        version: data.version,
        releaseDate: data.date
          ? dayjs(data.date).format(DATE_FORMAT.bigEndian)
          : null,
        changeLog: data.notes
      },
      type: "release"
    }
  };
};
