import { PAGINATION_SIZE, SORT_DIRECTIONS } from "gsi-ui-components";
import { isEmpty } from "lodash";
import DOMPurify from "dompurify";
import { parseISODate } from "./datesHelper";
import { getPaginationParam, getSortingParam } from "./URLHelper";

const DEFAULT_SORT_KEY = "createdOn";
const VALIDITY_PERIOD_KEY = "validityPeriod";

export const getNewsDtoInfo = response => {
  const newsInfoArray = [];

  response.data?.forEach(news => {
    const attributes = news?.attributes;
    newsInfoArray.push({
      id: news?.id,
      body: attributes?.body,
      createdOn: attributes?.createdOn,
      description: attributes?.description,
      title: attributes?.title,
      validityPeriod: attributes?.validityPeriod
    });
  });

  return newsInfoArray;
};

export const buildNewsReqBody = data => {
  const cleanBody = DOMPurify.sanitize(data.body);

  return {
    data: {
      attributes: {
        body: cleanBody,
        description: data.description,
        title: data.title,
        validityPeriod: {
          since: parseISODate(data.dates[0], false),
          to: parseISODate(data.dates[1], true)
        }
      },
      type: "news"
    }
  };
};

export const getFilterDatesParam = (key, filters) => {
  try {
    const filterDates = filters && filters[key];
    const dates = filterDates && filterDates[0].split("-");
    if (key && !isEmpty(dates)) {
      return `filter=${key}.since>=${parseISODate(
        dates[0],
        false
      )};${key}.since<=${parseISODate(
        dates[1],
        true
      )},${key}.to>=${parseISODate(dates[0], false)};${key}.to<=${parseISODate(
        dates[1],
        true
      )}`;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getParams = (
  page = 1,
  pageSize = PAGINATION_SIZE,
  sortKey = DEFAULT_SORT_KEY,
  sortDirection = SORT_DIRECTIONS.DESCEND,
  filters = null
) => {
  const paginationParams = getPaginationParam(page, pageSize);
  const sortingParams = getSortingParam(sortKey, sortDirection);
  const filterParams = getFilterDatesParam(VALIDITY_PERIOD_KEY, filters);

  return [paginationParams, sortingParams, filterParams]
    .filter(e => e && e.length > 0)
    .join("&");
};
