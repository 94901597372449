import React from "react";
import PropTypes from "prop-types";
import { Layout, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SORT_DIRECTIONS } from "gsi-ui-components";
import BoHeader from "../../layout/BoHeader";

const { Content } = Layout;

const defaultPagination = {
  defaultPageSize: 20,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50"]
};

const defaultLoading = isLoading => {
  return { spinning: isLoading, indicator: <LoadingOutlined /> };
};

const GenericTable = ({
  title,
  columns,
  dataSource,
  pagination = defaultPagination,
  loading = defaultLoading,
  isLoading = false,
  className = "",
  sortDirections = [SORT_DIRECTIONS.ASCEND, SORT_DIRECTIONS.DESCEND],
  onChange = () => undefined,
  onRow = () => undefined,
  rowKey = null,
  breadcrumbRoutes = null
}) => {
  return (
    <Layout>
      {title && <BoHeader title={title} breadcrumbRoutes={breadcrumbRoutes} />}
      <Content>
        <div className="table-container">
          <Table
            tableLayout="auto"
            columns={columns}
            scroll={{ x: true }}
            dataSource={dataSource}
            pagination={pagination}
            className={className}
            sortDirections={sortDirections}
            rowKey={rowKey}
            loading={loading && loading(isLoading)}
            onChange={onChange}
            onRow={onRow}
          />
        </div>
      </Content>
    </Layout>
  );
};

GenericTable.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  sortDirections: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onRow: PropTypes.func,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  breadcrumbRoutes: PropTypes.array
};

export default GenericTable;
