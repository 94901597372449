export const signOut = history => async dispatch => {
  const [
    { SIGN_OUT },
    userService,
    { getBoBackendLogoutUrl, BO_AUTH_COOKIE_NAME, BO_LOCATION_PATH }
  ] = await Promise.all([
    import("../helpers/types"),
    (await import("../helpers/userService")).default,
    import("gsi-ui-components")
  ]);

  await userService.closeSession(getBoBackendLogoutUrl());
  userService.deleteCookie(BO_AUTH_COOKIE_NAME);
  history.push(BO_LOCATION_PATH.ROOT);
  history.go(0);

  dispatch({ type: SIGN_OUT });
};
