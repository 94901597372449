import React from "react";
import PropTypes from "prop-types";
import { BaseButton } from "gsi-ui-components";
import { buttonConsts } from "../../../../helpers/consts";

const ClearFiltersButton = ({ isDisabled, clearFilters }) => {
  return (
    <BaseButton
      config={{
        type: buttonConsts.TYPE.LINK,
        size: buttonConsts.SIZE.SMALL,
        disabled: isDisabled
      }}
      onClick={() => clearFilters()}
      text="Clear filters"
    />
  );
};

export default ClearFiltersButton;

ClearFiltersButton.propTypes = {
  isDisabled: PropTypes.bool,
  clearFilters: PropTypes.func
};
