import React from "react";
import { Tag } from "antd";
import { capitalize } from "lodash";
import {
  REQUEST_STATUS,
  calculateAge,
  formatDate,
  parseDateFormat,
  sortFields,
  modalityFilters,
  surgeryStatusFilters,
  SURGERY_CASE_ATTR,
  newCaseState,
  QualityTag
} from "gsi-ui-components";
import {
  iconFiltered,
  iconFilteredCalendar,
  inputSearch,
  rangePicker
} from "../../../helpers/casesHelper";

export const parsePatientValues = caseInfo => {
  return {
    Name: caseInfo.data.patient.fullName,
    MRN: caseInfo.data.medicalRecordNumber,
    Gender: capitalize(caseInfo.data.patient.gender),
    "Date of birth": parseDateFormat(caseInfo.data.patient.birthDate),
    Age:
      caseInfo.data.patient.birthDate &&
      calculateAge(formatDate(caseInfo.data.patient.birthDate)).toString()
  };
};

export const showCloneStatusMessage = (
  patientName,
  cloneCaseStatus,
  message,
  clearCloneCaseData
) => {
  const statuses = {
    [REQUEST_STATUS.LOADING]: () =>
      message.loading({ content: `Cloning case...`, duration: 0 }),
    [REQUEST_STATUS.ERROR]: () =>
      message.error({ content: `Cloning failed ${patientName}`, duration: 3 }),
    [REQUEST_STATUS.SUCCESS]: () =>
      message.success({
        content: `Case cloned ${patientName}`,
        duration: 3
      })
  };

  (cloneCaseStatus === REQUEST_STATUS.ERROR ||
    cloneCaseStatus === REQUEST_STATUS.SUCCESS) &&
    message.destroy();

  cloneCaseStatus && statuses[cloneCaseStatus]();
  clearCloneCaseData();
};

export const getListColumn = (
  columnName,
  activeFiltersObject,
  clearFiltersButton = null,
  actionButtons = null
) => {
  let column;

  switch (columnName) {
    case SURGERY_CASE_ATTR.CASE_NUMBER:
      column = {
        title: "Case #",
        dataIndex: SURGERY_CASE_ATTR.CASE_NUMBER,
        key: SURGERY_CASE_ATTR.CASE_NUMBER,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.caseNumber, recordB.caseNumber)
      };
      break;
    case SURGERY_CASE_ATTR.SURGEON_NAME:
      column = {
        title: "Surgeon",
        dataIndex: SURGERY_CASE_ATTR.SURGEON_NAME,
        key: SURGERY_CASE_ATTR.SURGEON_NAME,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.surgeonName, recordB.surgeonName)
      };
      break;
    case SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER:
      column = {
        title: "Original Case #",
        dataIndex: SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER,
        key: SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER,
        ellipsis: true,
        sorter: (recordA, recordB) =>
          sortFields(recordA.originalCaseNumber, recordB.originalCaseNumber)
      };
      break;
    case SURGERY_CASE_ATTR.MODALITY:
      column = {
        title: "Modality",
        dataIndex: SURGERY_CASE_ATTR.MODALITY,
        key: SURGERY_CASE_ATTR.MODALITY,
        ellipsis: true,
        filters: modalityFilters,
        filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.MODALITY] || null,
        render: (text, record) => {
          return {
            children: (
              <React.Fragment>
                {record.modality && <QualityTag content={record.modality} />}
              </React.Fragment>
            )
          };
        }
      };
      break;
    case SURGERY_CASE_ATTR.MRN:
      column = {
        title: "MRN",
        dataIndex: SURGERY_CASE_ATTR.MRN,
        key: SURGERY_CASE_ATTR.MRN,
        ellipsis: true,
        filterDropdown: filter => inputSearch(filter, "Case"),
        filterIcon: filter => iconFiltered(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.medicalRecordNumber, recordB.medicalRecordNumber),
        filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.MRN] || null
      };
      break;

    case SURGERY_CASE_ATTR.SURGERY_DATE:
      column = {
        title: "Surgery Date",
        dataIndex: SURGERY_CASE_ATTR.SURGERY_DATE,
        key: SURGERY_CASE_ATTR.SURGERY_DATE,
        ellipsis: true,
        filterDropdown: ({ setSelectedKeys, confirm }) =>
          rangePicker(setSelectedKeys, confirm),
        filterIcon: filter => iconFilteredCalendar(filter),
        sorter: (recordA, recordB) => sortFields(recordA.date, recordB.date),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.SURGERY_DATE] || null
      };
      break;

    case SURGERY_CASE_ATTR.CASE_STATUS:
      column = {
        title: "Status",
        dataIndex: SURGERY_CASE_ATTR.CASE_STATUS,
        key: SURGERY_CASE_ATTR.CASE_STATUS,
        ellipsis: true,
        filters: surgeryStatusFilters,
        render: (text, record) => {
          const tagText = record.isDeclined
            ? newCaseState.DECLINED
            : text.toUpperCase();
          return {
            props: {
              className: `status-${tagText.toLowerCase()}`
            },
            children: <Tag>{tagText}</Tag>
          };
        },
        sorter: (recordA, recordB) =>
          sortFields(recordA.caseStatus, recordB.caseStatus),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.CASE_STATUS] || null
      };
      break;

    case SURGERY_CASE_ATTR.CLASSIFICATION:
      column = {
        title: "Comment",
        dataIndex: SURGERY_CASE_ATTR.CLASSIFICATION,
        key: SURGERY_CASE_ATTR.CLASSIFICATION,
        ellipsis: true,
        filterDropdown: filter => inputSearch(filter, "Comment"),
        filterIcon: filter => iconFiltered(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.classification, recordB.classification),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.CLASSIFICATION] || null
      };
      break;

    case SURGERY_CASE_ATTR.LAST_MODIFIED:
      column = {
        title: "Last modified",
        dataIndex: SURGERY_CASE_ATTR.LAST_MODIFIED,
        key: SURGERY_CASE_ATTR.LAST_MODIFIED,
        ellipsis: true,
        filterDropdown: ({ setSelectedKeys, confirm }) =>
          rangePicker(setSelectedKeys, confirm),
        filterIcon: filter => iconFilteredCalendar(filter),
        sorter: (recordA, recordB) =>
          sortFields(recordA.lastModified, recordB.lastModified),
        filteredValue:
          activeFiltersObject[SURGERY_CASE_ATTR.LAST_MODIFIED] || null
      };
      break;

    case "actions":
      column = {
        title: clearFiltersButton,
        dataIndex: "actions",
        key: "actions",
        render: (text, record) => actionButtons(text, record)
      };
      break;
    default:
      column = null;
  }

  return column;
};
