export const getTicketList =
  (paramUrl = null) =>
  async dispatch => {
    const [{ TICKET_LIST }, { getSupportTicketsUrl }, { sendGetRequest }] =
      await Promise.all([
        import(`../helpers/types`),
        import(`gsi-ui-components`),
        import(`../helpers/URLHelper`)
      ]);

    try {
      dispatch({
        type: TICKET_LIST.LOADING
      });

      const response = await sendGetRequest(getSupportTicketsUrl(paramUrl));

      dispatch({
        type: TICKET_LIST.SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: TICKET_LIST.ERROR
      });
    }
  };

export const setCaseTaskInfo = caseTaskInfo => async dispatch => {
  const { CASE_TASK } = await import(`../helpers/types`);

  dispatch({
    type: CASE_TASK.SET,
    payload: caseTaskInfo
  });
};

export const getCaseTaskInfo =
  (ticketIdParam, taskTypeParam, taskSubTypeParam) => async dispatch => {
    const [
      { CASE_TASK },
      { CASE_TASKS_PIPELINES, CASE_TASKS },
      { getPipelineUrl },
      { getTaskByParamsUrl, sendGetRequest }
    ] = await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/ticketsHelper`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);

    try {
      dispatch({
        type: CASE_TASK.LOADING
      });

      const response = await sendGetRequest(
        getTaskByParamsUrl(ticketIdParam, taskTypeParam, taskSubTypeParam)
      );

      const data = response.data;
      const taskData = data.data[0];
      const id = taskData.id;
      const attributes = taskData.attributes;

      const { taskType, taskSubType, taskResult: state } = attributes;

      const task =
        CASE_TASKS[taskType.toUpperCase()][taskSubType.toUpperCase()];
      const pipelineName = CASE_TASKS_PIPELINES[taskType.toUpperCase()];
      const url = getPipelineUrl(pipelineName);
      const caseTaskInfo = {
        id,
        state,
        pipelineName,
        url,
        task,
        taskType
      };

      dispatch({
        type: CASE_TASK.SUCCESS,
        payload: caseTaskInfo
      });
    } catch {
      dispatch({
        type: CASE_TASK.ERROR
      });
    }
  };

export const clearCaseTaskInfo = () => async dispatch => {
  const { CASE_TASK } = await import(`../helpers/types`);

  dispatch({
    type: CASE_TASK.CLEAR
  });
};

export const setTicketDetailInfo = ticketDetailInfo => async dispatch => {
  const { TICKET_DETAIL } = await import(`../helpers/types`);

  dispatch({
    type: TICKET_DETAIL.SET,
    payload: ticketDetailInfo
  });
};

export const clearTicketDetailInfo = () => async dispatch => {
  const { TICKET_DETAIL } = await import(`../helpers/types`);

  dispatch({
    type: TICKET_DETAIL.CLEAR
  });
};

export const getTicketReviewers = () => async dispatch => {
  const [
    { REVIEWERS_LIST },
    { sendGetRequest },
    { getUserListUrl, USER_ROLES }
  ] = await Promise.all([
    import(`../helpers/types`),
    import(`../helpers/URLHelper`),
    import(`gsi-ui-components`)
  ]);

  try {
    dispatch({
      type: REVIEWERS_LIST.LOADING
    });

    const response = await sendGetRequest(
      getUserListUrl(USER_ROLES.SUPPORT_REQUEST_REVIEWER)
    );

    dispatch({
      type: REVIEWERS_LIST.SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: REVIEWERS_LIST.ERROR
    });
  }
};
