import { requestStatus } from "gsi-ui-components";
import { NEWS } from "../../helpers/types";

const initialState = {
  newsData: null,
  status: null,
  totalRecords: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEWS.LOADING:
      return { ...initialState, status: requestStatus.LOADING };
    case NEWS.SUCCESS:
      return {
        ...state,
        newsData: action.payload?.newsData,
        totalRecords: action.payload?.totalRecords,
        status: requestStatus.SUCCESS
      };
    case NEWS.ERROR:
      return { ...initialState, status: requestStatus.ERROR };
    case NEWS.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
