import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useErrorBoundary } from "react-error-boundary";
import { message, Button, Input, Form, DatePicker, Select } from "antd";
import { BO_LOCATION_PATH, REQUEST_STATUS } from "gsi-ui-components";
import {
  clearSubmitNotificationStatus,
  submitNotification
} from "../../actions/notifications/notificationsSubmit";
import { ERROR_MESSAGE } from "../../helpers/consts";
import "antd/lib/select/style/index.css";
import "./NotificationsForm.less";

const NotificationsForm = ({
  notificationsData,
  submitNotification,
  submitStatus,
  clearSubmitNotificationStatus,
  isEditing
}) => {
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();
  const [form] = Form.useForm();
  const [formSubmited, setFormSubmited] = useState(false);

  useEffect(() => {
    if (submitStatus === REQUEST_STATUS.SUCCESS) {
      message.success("Notification sent");
      history.push(BO_LOCATION_PATH.ROOT);
      clearSubmitNotificationStatus();
    } else if (submitStatus === REQUEST_STATUS.ERROR) {
      message.error(ERROR_MESSAGE);
      clearSubmitNotificationStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitStatus]);

  useEffect(() => {
    if (notificationsData) {
      onFill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData]);

  const onFinish = async values => {
    setFormSubmited(true);
    await submitNotification(values, notificationsData?.id ?? null);
    setFormSubmited(false);
  };

  const onFill = () => {
    try {
      form.setFieldsValue({
        date: dayjs(notificationsData.releaseDate),
        version: notificationsData.version,
        notes: notificationsData.changeLog
      });
    } catch (error) {
      console.error(error);
      showBoundary();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      wrapperCol={{ span: 18 }}
      className="form-container"
    >
      <Form.Item
        name="date"
        align="middle"
        justify="center"
        rules={[{ required: true, message: "Please select a date" }]}
      >
        <DatePicker
          autoFocus
          size="small"
          style={{ width: "100%" }}
          maxLength={50}
          placeholder="Select release date"
        />
      </Form.Item>
      <Form.Item
        name="version"
        align="middle"
        justify="center"
        rules={[{ required: true, message: "Please enter a version" }]}
      >
        <Input maxLength={50} placeholder="Enter release version" />
      </Form.Item>
      <Form.Item
        name="notes"
        align="middle"
        justify="center"
        rules={[{ required: true, message: "Please list release notes" }]}
      >
        <Select
          open={false}
          mode="tags"
          style={{ width: "100%" }}
          tokenSeparators={["\n", "\r\n"]}
          placeholder="List release notes"
        />
      </Form.Item>
      <Form.Item align="middle" justify="center">
        {!isEditing ? (
          <div className="btn-container">
            <Button
              block
              onClick={() => history.push(BO_LOCATION_PATH.NOTIFICATIONS)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={formSubmited}
            >
              Send
            </Button>
          </div>
        ) : (
          <div className="btn-container editing">
            <Button
              className="editing-send-btn"
              type="primary"
              htmlType="submit"
              block
              disabled={formSubmited}
            >
              Save changes
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

NotificationsForm.propTypes = {
  notificationsData: PropTypes.object,
  submitNotification: PropTypes.func,
  clearSubmitNotificationStatus: PropTypes.func,
  submitStatus: PropTypes.string,
  isEditing: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    submitStatus: state.notificationsSubmit.status
  };
};

export default React.memo(
  connect(mapStateToProps, {
    submitNotification,
    clearSubmitNotificationStatus
  })(NotificationsForm)
);
