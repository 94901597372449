import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const parseISODate = (date, eod = false) => {
  return dayjs
    .utc(date)
    .set("hour", eod ? 23 : 0)
    .set("minute", eod ? 59 : 0)
    .set("second", eod ? 59 : 0)
    .set("millisecond", eod ? 999 : 0)
    .toISOString();
};
