import axios from "axios";
import {
  addNewCaseFilesToBody,
  getBoRefreshTokenUrl,
  getPipelineBuildUrl,
  CASE_TASKS_PIPELINES,
  SORT_DIRECTIONS,
  getNotificationsUrl
} from "gsi-ui-components";
import userService from "./userService";
import { addUpdateFileToBody } from "./ticketsHelper";

export const BODY_PARSER = {
  UNDEFINED: "UNDEFINED",
  JSON: "JSON",
  QUERY_STRING: "QUERY_STRING"
};

export const START_INDEX_PARAM = "?startIndex=";
export const PAGE_SIZE_PARAM = "&pageSize=";
export const JSON_CONTENT_TYPE = "application/json";
export const MULTIPART_FORM_DATA = "multipart/form-data";
export const FORM_ENCODED_CONTENT_TYPE = "application/x-www-form-urlencoded";
const URL_SEPARATOR = `/`;
const URL_WITH_PARAM_DELIMITER = `?`;
const PARAM_SEPARATOR = `&`;
const PARAM_VALUE = `=`;
const KEYCLOACK_RESOURCE = {
  REALMS: "realms",
  PROTOCOL: "protocol",
  OPEN_ID_CONNECT: "openid-connect",
  AUTH: "auth",
  PARAM_CLIENT_ID: "client_id",
  PARAM_RESPONSE_TYPE: "response_type",
  PARAM_REDIRECT_URI: "redirect_uri"
};
const API_RESOURCE = {
  SERVICES: "services",
  SUPPORT: "support",
  TASKS: "tasks",
  ID_PARAM: "id",
  ISSUE_KEY: "issueKey",
  TASK_TYPE: "taskType",
  TASK_SUB_TYPE: "taskSubType"
};

const paramFactory = params => {
  let paramStrings = params.map(param => {
    return `${param.param}${PARAM_VALUE}${param.value}`;
  });
  return paramStrings.join(PARAM_SEPARATOR);
};

const urlWithParamsFactory = (url, paramsString) => {
  return `${url}${URL_WITH_PARAM_DELIMITER}${paramsString}`;
};

const pathFactory = (...parts) => {
  return parts.join(URL_SEPARATOR).replace(/([^:]\/)\/+/g, "$1");
};

export const getTaskByParamsUrl = (ticketId, taskType, taskSubType) => {
  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TASKS
  );

  const params = [
    {
      param: API_RESOURCE.ISSUE_KEY,
      value: ticketId
    },
    {
      param: API_RESOURCE.TASK_TYPE,
      value: taskType
    },
    {
      param: API_RESOURCE.TASK_SUB_TYPE,
      value: taskSubType
    }
  ];

  return urlWithParamsFactory(url, paramFactory(params));
};

const getLoginUrl = redirectUrl => {
  const url = pathFactory(
    process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOACK_RESOURCE.REALMS,
    process.env.REACT_APP_KEYCLOAK_REALM,
    KEYCLOACK_RESOURCE.PROTOCOL,
    KEYCLOACK_RESOURCE.OPEN_ID_CONNECT,
    KEYCLOACK_RESOURCE.AUTH
  );

  const params = [
    {
      param: KEYCLOACK_RESOURCE.PARAM_CLIENT_ID,
      value: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    },
    { param: KEYCLOACK_RESOURCE.PARAM_RESPONSE_TYPE, value: "code" },
    { param: KEYCLOACK_RESOURCE.PARAM_REDIRECT_URI, value: redirectUrl }
  ];

  return urlWithParamsFactory(url, paramFactory(params));
};

export const refreshToken = async () => {
  if (userService.isTokenExpired()) {
    try {
      await userService.updateToken(getBoRefreshTokenUrl());
    } catch (error) {
      window.location.href = getLoginUrl(window.location.href);
    }
  }
};

const refreshAndRetrieveToken = async () => {
  await refreshToken();
  return userService.getToken();
};

export const getPaginationParam = (page, pageSize) => {
  if (page && pageSize) {
    return `page[size]=${pageSize}&page[number]=${page}&page[totals]`;
  } else {
    return `page[totals]`;
  }
};

export const getSortingParam = (key, direction) => {
  if (key && direction) {
    return `sort=${direction === SORT_DIRECTIONS.DESCEND ? "-" : ""}${key}`;
  }
};

export const sendJenkinsBuildJob = async (
  pipeline,
  body,
  files,
  jenkinsAuth
) => {
  const pipelineUrl = getPipelineBuildUrl(pipeline);
  const auth = Buffer.from(
    `${jenkinsAuth.username}:${jenkinsAuth.apiKey}`,
    "utf-8"
  ).toString("base64");

  let formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }
  if (pipeline === CASE_TASKS_PIPELINES.INJECT) {
    addNewCaseFilesToBody(files, formData);
  } else if (pipeline === CASE_TASKS_PIPELINES.UPDATE) {
    addUpdateFileToBody(files, formData);
  }

  return axios.post(pipelineUrl, formData, {
    headers: {
      "Content-Type": MULTIPART_FORM_DATA,
      Authorization: `Basic ${auth}`
    }
  });
};

export const sendGetRequest = async (
  url,
  body = null,
  skipCache = false,
  additionalHeaders = {}
) => {
  await refreshToken();
  const baseHeaders = {
    Authorization: "Bearer ".concat(userService.getToken())
  };

  if (skipCache) {
    additionalHeaders["Cache-Control"] = "no-cache";
  }
  const headers = { ...baseHeaders, ...additionalHeaders };
  if (body) {
    return axios.get(url, body, {
      headers: headers
    });
  } else {
    return axios.get(url, {
      headers: headers
    });
  }
};

export const sendPostRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.post(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.post(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.post(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendPutRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.put(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.put(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.put(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendDeleteRequest = async (url, contentType, isLogin = false) => {
  const headers = {
    "Content-Type": contentType
  };
  if (!isLogin) {
    await refreshToken();
    headers.Authorization = "Bearer ".concat(userService.getToken());
  }
  return axios.delete(url, { headers });
};

export const sendPatchRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.patch(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.patch(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.patch(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const filterNotificationByIdUrl = id => {
  const params = [{ param: "filter[release]=id=", value: id }];
  return urlWithParamsFactory(getNotificationsUrl(), paramFactory(params));
};
