import { getTasksDtoInfo } from "../helpers/ticketsHelper";

export const getTicketTasks = issueId => async dispatch => {
  const [{ TICKET_TASKS }, { sendGetRequest }, { getTasksUrlByIssueParam }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/URLHelper`),
      import(`gsi-ui-components`)
    ]);

  try {
    dispatch({
      type: TICKET_TASKS.LOADING
    });

    const response = await sendGetRequest(getTasksUrlByIssueParam(issueId));
    const tasksInfo = getTasksDtoInfo(response.data);
    dispatch({
      type: TICKET_TASKS.SUCCESS,
      payload: tasksInfo
    });
  } catch (error) {
    dispatch({
      type: TICKET_TASKS.ERROR
    });
  }
};

export const clearTicketTasks = () => async dispatch => {
  const { TICKET_TASKS } = await import(`../helpers/types`);

  dispatch({
    type: TICKET_TASKS.CLEAR
  });
};

export const updateTaskState = (taskId, status) => async dispatch => {
  const [
    { TICKET_UPDATE_TASKS },
    { sendPatchRequest },
    { getTaskUrl, BODY_PARSER, JSON_CONTENT_TYPE }
  ] = await Promise.all([
    import(`../helpers/types`),
    import(`../helpers/URLHelper`),
    import(`gsi-ui-components`)
  ]);

  try {
    dispatch({
      type: TICKET_UPDATE_TASKS.LOADING
    });

    await sendPatchRequest(
      getTaskUrl(taskId),
      BODY_PARSER.JSON,
      { taskStatus: status },
      JSON_CONTENT_TYPE
    );

    dispatch({
      type: TICKET_UPDATE_TASKS.SUCCESS
    });
  } catch (error) {
    dispatch({
      type: TICKET_UPDATE_TASKS.ERROR
    });
  }
};

export const clearTaskState = () => async dispatch => {
  const { TICKET_UPDATE_TASKS } = await import(`../helpers/types`);

  dispatch({
    type: TICKET_UPDATE_TASKS.CLEAR
  });
};
