import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Modal, Spin } from "antd";
import { isEmpty } from "lodash";
import {
  REQUEST_STATUS,
  dateToStringFormat,
  getPipelineUrl
} from "gsi-ui-components";
import {
  CASE_TASKS,
  TASK_TYPE,
  CASE_TASKS_PIPELINES
} from "../../../helpers/ticketsHelper";
import DetailInfo from "./DetailInfo";
import { ReactComponent as TicketIcon } from "../../../resources/requestSupport/ticket-vector.svg";
import { clearTicketDetailInfo } from "../../../actions/tickets";
import { getTicketTasks, clearTicketTasks } from "../../../actions/tasks";
import "./RequestDetails.less";

const buildCaseTaskInfoObject = (element, caseId, userId, ticketId) => {
  const taskType = element.taskType.toUpperCase();
  const taskSubType = element.taskSubType.toUpperCase();
  const caseTaskPipeline = CASE_TASKS_PIPELINES[taskType];
  const caseTask = CASE_TASKS[taskType][taskSubType];

  return {
    id: element.id,
    state: element.state,
    caseId,
    userId,
    ticketId: ticketId,
    pipelineName: caseTaskPipeline,
    task: caseTask,
    url: getPipelineUrl(caseTaskPipeline)
  };
};

const renderTasks = (ticketInfo, caseId, userId, ticketId, status) => {
  if (status === REQUEST_STATUS.LOADING) {
    const antIcon = <LoadingOutlined spin />;
    return <Spin className="spinner" indicator={antIcon} />;
  } else if (!isEmpty(ticketInfo)) {
    return (
      <div>
        <div className="border-light"></div>
        {ticketInfo.map((element, index) => (
          <React.Fragment key={element.description}>
            <DetailInfo
              index={index + 1}
              icon="check-circle"
              title={element.description}
              content={TASK_TYPE[element.taskType.toUpperCase()]}
              caseTaskInfo={buildCaseTaskInfoObject(
                element,
                caseId,
                userId,
                ticketId
              )}
            />
            <div className="border-light"></div>
          </React.Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <div className="icon-container">
        <LegacyIcon className="error-icon" type={"exclamation-circle"} />
      </div>
    );
  }
};

const RequestDetails = ({
  caseId,
  userId,
  issueId,
  ticketId,
  status,
  reason,
  date,
  visible,
  setShowDetails,
  clearTicketDetailInfo,
  clearTicketTasks,
  getTicketTasks,
  tasks
}) => {
  const detailInfo = [
    { id: 0, title: "Ticket ID", content: ticketId },
    { id: 1, title: "Case ID", content: caseId },
    { id: 2, title: "User ID", content: userId },
    { id: 3, title: "Status", content: status },
    { id: 4, title: "Reason", content: reason },
    { id: 5, title: "Date", content: dateToStringFormat(date) }
  ];

  const [ticketInfo, setTicketInfo] = useState([]);

  useEffect(() => {
    visible && issueId && getTicketTasks(issueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueId, visible]);

  useEffect(() => {
    if (tasks.status === REQUEST_STATUS.SUCCESS) {
      setTicketInfo(tasks.responseData);
    }
  }, [tasks]);

  return (
    <Modal
      title="Request Details"
      visible={visible}
      footer={null}
      transitionName=""
      onCancel={() => {
        setShowDetails(false);
        clearTicketTasks();
        clearTicketDetailInfo();
      }}
      className="request-support-modal"
      style={{
        float: "right",
        height: "100%",
        width: "100%",
        padding: 0,
        top: 0
      }}
    >
      <div className="request-modal-body">
        <div className="request-modal-element">
          <div className="element-title">
            Manual Support Request <TicketIcon className="ticket-icon" />
          </div>
          <div className="border"></div>
          {detailInfo.map(element => (
            <DetailInfo
              key={element.id}
              title={element.title}
              content={element.content}
            />
          ))}
          <div className="border"></div>
        </div>
        <div className="request-modal-element">
          <div className="element-title">Tasks</div>
          {renderTasks(ticketInfo, caseId, userId, ticketId, tasks.status)}
        </div>
        <div className="border"></div>
      </div>
    </Modal>
  );
};

RequestDetails.propTypes = {
  caseId: PropTypes.string,
  userId: PropTypes.string,
  issueId: PropTypes.string,
  ticketId: PropTypes.string,
  status: PropTypes.string,
  reason: PropTypes.string,
  date: PropTypes.string,
  visible: PropTypes.bool,
  setShowDetails: PropTypes.func,
  clearTicketDetailInfo: PropTypes.func,
  clearTicketTasks: PropTypes.func,
  getTicketTasks: PropTypes.func,
  tasks: PropTypes.object
};

const mapStateToProps = state => {
  return { tasks: state.ticketTasks };
};

export default React.memo(
  connect(mapStateToProps, {
    clearTicketTasks,
    clearTicketDetailInfo,
    getTicketTasks
  })(RequestDetails)
);
