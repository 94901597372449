import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import PropTypes from "prop-types";
import GenericTable from "../common/genericTable/GenericTable";
import {
  EditOutlined,
  CalendarOutlined,
  FormOutlined
} from "@ant-design/icons";
import {
  BO_LOCATION_PATH,
  NOTIFICATIONS_ATTR,
  PAGINATION_SIZE,
  REQUEST_STATUS,
  SORT_DIRECTIONS
} from "gsi-ui-components";
import {
  clearNotifications,
  getNotifications
} from "../../actions/notifications/notifications";
import dayjs from "dayjs";
import ActionButtons from "../common/genericTable/components/ActionButtons";
import BoHeader from "../layout/BoHeader";
import { rangePicker } from "../../helpers/casesHelper";
import { getParams } from "../../helpers/notificationsHelper";

const actionButtons = (_text, record) => {
  return (
    <ActionButtons
      record={record}
      buttons={[
        {
          title: "Edit",
          iconComponent: EditOutlined,
          action: () => {}
        }
      ]}
    />
  );
};

const columns = [
  {
    title: "Release date",
    dataIndex: NOTIFICATIONS_ATTR.RELEASE_DATE,
    key: NOTIFICATIONS_ATTR.RELEASE_DATE,
    ellipsis: true,
    width: "50%",
    filterOnClose: true,
    filterDropdown: ({ setSelectedKeys, confirm }) =>
      rangePicker(setSelectedKeys, confirm),
    filterIcon: () => <CalendarOutlined />,
    render: (_text, record) => {
      try {
        const date = record[NOTIFICATIONS_ATTR.RELEASE_DATE];
        return date ? dayjs.utc(date).format("MM/DD/YYYY") : "No date";
      } catch {
        return "-";
      }
    }
  },
  {
    title: "Release version",
    dataIndex: NOTIFICATIONS_ATTR.VERSION,
    key: NOTIFICATIONS_ATTR.VERSION,
    ellipsis: true,
    sorter: true,
    width: "50%"
  },
  {
    dataIndex: "actions",
    key: NOTIFICATIONS_ATTR.ID,
    width: "5rem",
    render: (text, record) => actionButtons(text, record)
  }
];

const NotificationsList = ({
  getNotifications,
  clearNotifications,
  totalNotifications,
  notifications,
  status,
  history
}) => {
  useEffect(() => {
    getNotifications(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (page, pageSize, sortKey, sortDirection, filters) => {
    getNotifications(
      getParams(
        page,
        pageSize,
        sortDirection && sortKey,
        sortDirection,
        filters
      )
    );
  };

  return (
    <Layout>
      <BoHeader
        title="Release notifications"
        buttonTitle="Create release notification"
        buttonIcon={<FormOutlined />}
        onButtonClick={() => history.push(BO_LOCATION_PATH.NOTIFICATIONS_ADD)}
      />
      <GenericTable
        columns={columns}
        dataSource={notifications}
        rowKey={record => record[NOTIFICATIONS_ATTR.ID]}
        sortDirections={[SORT_DIRECTIONS.ASCEND, SORT_DIRECTIONS.DESCEND]}
        isLoading={!status || status === REQUEST_STATUS.LOADING}
        pagination={{
          total: totalNotifications,
          defaultPageSize: PAGINATION_SIZE,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"]
        }}
        onChange={(pagination, filters, sorter) => {
          handleChange(
            pagination.current,
            pagination.pageSize,
            sorter.columnKey,
            sorter.order,
            filters
          );
        }}
        onRow={record => {
          return {
            onClick: () => {
              clearNotifications();
              history.push(
                `${BO_LOCATION_PATH.NOTIFICATIONS_EDIT}/${
                  record[NOTIFICATIONS_ATTR.ID]
                }`
              );
            }
          };
        }}
      />
    </Layout>
  );
};

NotificationsList.propTypes = {
  getNotifications: PropTypes.func,
  clearNotifications: PropTypes.func,
  notifications: PropTypes.array,
  status: PropTypes.string,
  history: PropTypes.object,
  totalNotifications: PropTypes.number
};

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notificationsData,
    totalNotifications: state.notifications.totalRecords,
    status: state.notifications.status
  };
};

export default connect(mapStateToProps, {
  getNotifications,
  clearNotifications
})(NotificationsList);
