import { REQUEST_STATUS } from "gsi-ui-components";
import { CASE_INFO, LOAD_IMPLANTS_INFO } from "../helpers/types";

const initialState = {
  status: null,
  data: {},
  implantsInfo: null,
  loadImplantsInfoStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASE_INFO.LOADING:
      return {
        ...state,
        data: {},
        status: REQUEST_STATUS.LOADING
      };
    case CASE_INFO.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload
      };
    case CASE_INFO.ERROR:
      return {
        ...state,
        data: {},
        status: REQUEST_STATUS.ERROR
      };
    case CASE_INFO.CLEAR:
      return {
        ...state,
        data: {},
        status: null
      };
    case LOAD_IMPLANTS_INFO.LOADING:
      return {
        ...state,
        loadImplantsInfoStatus: REQUEST_STATUS.LOADING
      };
    case LOAD_IMPLANTS_INFO.SUCCESS:
      return {
        ...state,
        implantsInfo: action.payload,
        loadImplantsInfoStatus: REQUEST_STATUS.SUCCESS
      };
    case LOAD_IMPLANTS_INFO.ERROR:
      return {
        ...state,
        loadImplantsInfoStatus: REQUEST_STATUS.ERROR
      };
    default:
      return state;
  }
};
