import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useErrorBoundary } from "react-error-boundary";
import { message, Button, Input, Form, DatePicker } from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { validateHTML } from "html-validate-offline";
import { BO_LOCATION_PATH, REQUEST_STATUS } from "gsi-ui-components";
import {
  clearSubmitNewsStatus,
  submitNews
} from "../../actions/news/newsSubmit";
import { ERROR_MESSAGE } from "../../helpers/consts";
import "./NewsForm.less";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const htmlValidator = (_, value) => {
  const errorMsg = "Invalid HTML string";

  try {
    if (value) {
      const report = validateHTML(value);
      if (!report.status) {
        return Promise.reject(new Error(`${errorMsg}: ${report.message}`));
      }
    }

    return Promise.resolve();
  } catch (e) {
    console.error(e);
    return Promise.reject(new Error(errorMsg));
  }
};

const NewsForm = ({
  newsData,
  submitNews,
  submitStatus,
  clearSubmitNewsStatus
}) => {
  const history = useHistory();
  const { showBoundary } = useErrorBoundary();
  const [form] = Form.useForm();

  useEffect(() => {
    if (submitStatus === REQUEST_STATUS.SUCCESS) {
      message.success("News sent");
      history.push(BO_LOCATION_PATH.ROOT);
      clearSubmitNewsStatus();
    } else if (submitStatus === REQUEST_STATUS.ERROR) {
      message.error(ERROR_MESSAGE);
      clearSubmitNewsStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitStatus]);

  useEffect(() => {
    if (newsData) {
      onFill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsData]);

  const onFinish = values => {
    submitNews(values, newsData?.id ?? null);
  };

  const onFill = () => {
    try {
      const since = dayjs(newsData.validityPeriod.since.slice(0, 10));
      const to = dayjs(newsData.validityPeriod.to.slice(0, 10));

      form.setFieldsValue({
        title: newsData.title,
        description: newsData.description,
        body: newsData.body,
        dates: [since, to]
      });
    } catch (error) {
      console.error(error);
      showBoundary();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      wrapperCol={{ span: 18 }}
      className="form-container"
    >
      <Form.Item
        name="title"
        align="middle"
        justify="center"
        rules={[{ required: true, message: "Please input a title" }]}
      >
        <Input autoFocus maxLength={50} placeholder="Add news title here" />
      </Form.Item>
      <Form.Item
        name="description"
        align="middle"
        justify="center"
        rules={[{ required: true, message: "Please input a description" }]}
      >
        <Input maxLength={50} placeholder="Add news description here" />
      </Form.Item>
      <Form.Item
        name="body"
        align="middle"
        justify="center"
        rules={[
          { required: true, message: "Please input a news body" },
          {
            validator: htmlValidator
          }
        ]}
      >
        <TextArea rows={6} placeholder="Add news here" />
      </Form.Item>
      <Form.Item
        name="dates"
        align="middle"
        justify="center"
        rules={[
          {
            required: true,
            message: "Please input a valid date range"
          }
        ]}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item align="middle" justify="center">
        <div className="btn-container">
          <Button block onClick={() => history.push(BO_LOCATION_PATH.NEWS)}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" block>
            Send
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

NewsForm.propTypes = {
  newsData: PropTypes.object,
  submitNews: PropTypes.func,
  clearSubmitNewsStatus: PropTypes.func,
  submitStatus: PropTypes.string
};

const mapStateToProps = state => {
  return {
    submitStatus: state.newsSubmit.status
  };
};

export default React.memo(
  connect(mapStateToProps, { submitNews, clearSubmitNewsStatus })(NewsForm)
);
