import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import PropTypes from "prop-types";
import GenericTable from "../common/genericTable/GenericTable";
import {
  EditOutlined,
  CalendarOutlined,
  FormOutlined
} from "@ant-design/icons";
import {
  BO_LOCATION_PATH,
  NEWS_ATTR,
  PAGINATION_SIZE,
  REQUEST_STATUS,
  SORT_DIRECTIONS
} from "gsi-ui-components";
import { getNews } from "../../actions/news/news";
import dayjs from "dayjs";
import ActionButtons from "../common/genericTable/components/ActionButtons";
import BoHeader from "../layout/BoHeader";
import { rangePicker } from "../../helpers/casesHelper";
import { getParams } from "../../helpers/newsHelper";

const actionButtons = (_text, record) => {
  return (
    <ActionButtons
      record={record}
      buttons={[
        {
          title: "Edit",
          iconComponent: EditOutlined,
          action: () => {}
        }
      ]}
    />
  );
};

const columns = [
  {
    title: "Title",
    dataIndex: NEWS_ATTR.TITLE,
    key: NEWS_ATTR.TITLE,
    ellipsis: true,
    sorter: true,
    width: "50%"
  },
  {
    title: "Start/End Date",
    dataIndex: NEWS_ATTR.VALIDITY_PERIOD,
    key: NEWS_ATTR.VALIDITY_PERIOD,
    ellipsis: true,
    width: "50%",
    filterOnClose: true,
    filterDropdown: ({ setSelectedKeys, confirm }) =>
      rangePicker(setSelectedKeys, confirm),
    filterIcon: () => <CalendarOutlined />,
    render: (_text, record) => {
      try {
        const sinceDate = record[NEWS_ATTR.VALIDITY_PERIOD]["since"];
        const toDate = record[NEWS_ATTR.VALIDITY_PERIOD]["to"];
        const sinceDateFormated = sinceDate
          ? dayjs.utc(sinceDate).format("MM/DD/YYYY")
          : "No date";
        const toDateFormated = toDate
          ? dayjs.utc(toDate).format("MM/DD/YYYY")
          : "No date";
        return {
          children: `${sinceDateFormated}-${toDateFormated}`
        };
      } catch {
        return {
          children: "-"
        };
      }
    }
  },
  {
    dataIndex: "actions",
    key: NEWS_ATTR.ID + 2,
    width: "5rem",
    render: (text, record) => actionButtons(text, record)
  }
];

const NewsList = ({ getNews, totalNews, news, status, history }) => {
  useEffect(() => {
    getNews(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (page, pageSize, sortKey, sortDirection, filters) => {
    getNews(getParams(page, pageSize, sortKey, sortDirection, filters));
  };

  return (
    <Layout>
      <BoHeader
        title="News"
        buttonTitle="Create news"
        buttonIcon={<FormOutlined />}
        onButtonClick={() => history.push(BO_LOCATION_PATH.NEWS_ADD)}
      />
      <GenericTable
        columns={columns}
        dataSource={news}
        rowKey={record => record[NEWS_ATTR.ID]}
        sortDirections={[SORT_DIRECTIONS.ASCEND, SORT_DIRECTIONS.DESCEND]}
        isLoading={!status || status === REQUEST_STATUS.LOADING}
        pagination={{
          total: totalNews,
          defaultPageSize: PAGINATION_SIZE,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"]
        }}
        onChange={(pagination, filters, sorter) => {
          handleChange(
            pagination.current,
            pagination.pageSize,
            sorter.columnKey,
            sorter.order,
            filters
          );
        }}
        onRow={record => {
          return {
            onClick: () => {
              history.push(
                `${BO_LOCATION_PATH.NEWS_EDIT}/${record[NEWS_ATTR.ID]}`
              );
            }
          };
        }}
      />
    </Layout>
  );
};

NewsList.propTypes = {
  getNews: PropTypes.func,
  news: PropTypes.array,
  status: PropTypes.string,
  history: PropTypes.object,
  totalNews: PropTypes.number
};

const mapStateToProps = state => {
  return {
    news: state.news.newsData,
    totalNews: state.news.totalRecords,
    status: state.news.status
  };
};

export default connect(mapStateToProps, { getNews })(NewsList);
