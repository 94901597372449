import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Layout, Row, Spin } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { ErrorBoundary } from "react-error-boundary";
import { BO_LOCATION_PATH, InfoCard, REQUEST_STATUS } from "gsi-ui-components";
import BoHeader from "../layout/BoHeader";
import NewsForm from "./NewsForm";
import { getNewsById } from "../../actions/news/news";
import NewsError from "./NewsError";
import "./NewsEdit.less";

const { Content } = Layout;

const NewsEdit = ({ news, status, getNewsById }) => {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    params.newsIdParam && getNewsById(params.newsIdParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const getDisplayScreen = () => {
    switch (status) {
      case REQUEST_STATUS.ERROR:
        return <NewsError />;
      case REQUEST_STATUS.SUCCESS:
        return <NewsForm newsData={news[0]} />;
      case REQUEST_STATUS.LOADING:
      default:
        return <Spin className={`spinner`} indicator={<LoadingOutlined />} />;
    }
  };

  return (
    <React.Fragment>
      <BoHeader title={"Edit news"} />
      <Content>
        <div className="content-container">
          <Row align="middle" justify="center" type={"flex"}>
            <Col span={12}>
              <InfoCard>
                <ErrorBoundary fallback={<NewsError />}>
                  <div className="btn-containter">
                    <CloseOutlined
                      onClick={() => history.push(BO_LOCATION_PATH.NEWS)}
                    />
                  </div>
                  {getDisplayScreen()}
                </ErrorBoundary>
              </InfoCard>
            </Col>
          </Row>
        </div>
      </Content>
    </React.Fragment>
  );
};

NewsEdit.propTypes = {
  getNewsById: PropTypes.func,
  news: PropTypes.array,
  status: PropTypes.string
};

const mapStateToProps = state => {
  return {
    news: state.news.newsData,
    status: state.news.status
  };
};

export default connect(mapStateToProps, { getNewsById })(NewsEdit);
