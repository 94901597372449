import React, { useEffect, useMemo, useRef, useState } from "react";
import { message } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CopyOutlined, EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  SURGERY_CASE_ATTR,
  REQUEST_STATUS,
  newCaseState
} from "gsi-ui-components";
import {
  filterCases,
  getCaseSearchPath,
  parseInputData
} from "../../helpers/casesHelper";
import {
  getCaseInfo,
  getCaseList,
  getPlanningInfo,
  clearCloneCaseData,
  clearCaseInfo,
  clearPlanningInfo
} from "../../actions/cases";
import ActionButtons from "../common/genericTable/components/ActionButtons";
import ClearFiltersButton from "../common/genericTable/components/ClearFiltersButton";
import GenericTable from "../common/genericTable/GenericTable";
import CloneCaseModal from "./components/CloneCaseModal";
import { useViewReport, useCloneCase } from "./hooks";
import "./CaseList.less";
import { getListColumn } from "./helpers/caseListHelper";

const CaseList = ({
  getCaseInfo,
  getCaseList,
  cloneCaseStatus,
  clearCloneCaseData,
  getPlanningInfo,
  caseListData,
  caseInfo,
  planningInfo,
  responseData,
  status,
  implantsInfo,
  loadImplantsInfoStatus,
  clearCaseInfo,
  clearPlanningInfo,
  history
}) => {
  const casesInfo = useRef({});
  const activeFilters = useRef("");
  const [isCloneCaseModalVisible, setIsCloneCaseModalVisible] = useState(false);
  const [filtersCleared, setFiltersCleared] = useState(true);
  const [activeFiltersObject, setActiveFiltersObject] = useState([]);

  useEffect(() => {
    getCaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewReportRequested = useViewReport(
    caseInfo,
    clearCaseInfo,
    planningInfo,
    getPlanningInfo,
    clearPlanningInfo,
    implantsInfo
  );

  const cloneInfo = useCloneCase(
    cloneCaseStatus,
    clearCloneCaseData,
    getCaseList
  );

  const ACTION_BUTTONS = useMemo(
    () => [
      {
        title: "Copy case",
        iconComponent: CopyOutlined,
        action: record => {
          cloneInfo.current = {
            patientName: record.patientName,
            caseNumber: record.caseNumber
          };
          setIsCloneCaseModalVisible(true);
        }
      },
      {
        title: "View case",
        iconComponent: EyeOutlined,
        action: record => {
          history.push(getCaseSearchPath(record.id));
        }
      },
      {
        title: "View report",
        iconComponent: FilePdfOutlined,
        disabled: record => record.caseStatus !== newCaseState.PLANNED,
        action: record => {
          if (
            record.caseStatus === newCaseState.PLANNED &&
            loadImplantsInfoStatus === REQUEST_STATUS.SUCCESS
          ) {
            viewReportRequested.current = true;
            getCaseInfo(record.id);
            message.loading("Generating PDF report");
          }
        }
      }
    ],
    [
      cloneInfo,
      getCaseInfo,
      history,
      loadImplantsInfoStatus,
      viewReportRequested
    ]
  );

  const actionButtons = (_text, record) => {
    return <ActionButtons record={record} buttons={ACTION_BUTTONS} />;
  };

  const clearFiltersButton = (
    <ClearFiltersButton
      isDisabled={filtersCleared}
      clearFilters={() => {
        setFiltersCleared(true);
        setActiveFiltersObject([]);
        filterCases([], getCaseList, activeFilters, setFiltersCleared);
      }}
    />
  );

  const columns = [
    getListColumn(SURGERY_CASE_ATTR.CASE_NUMBER, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.SURGEON_NAME, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.MODALITY, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.MRN, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.SURGERY_DATE, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.CASE_STATUS, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.CLASSIFICATION, activeFiltersObject),
    getListColumn(SURGERY_CASE_ATTR.LAST_MODIFIED, activeFiltersObject),
    getListColumn(
      "actions",
      activeFiltersObject,
      clearFiltersButton,
      actionButtons
    )
  ];

  return (
    <React.Fragment>
      <GenericTable
        columns={columns}
        title="Cases List"
        className="cases-list"
        dataSource={parseInputData(caseListData, responseData)}
        isLoading={!status || status === REQUEST_STATUS.LOADING}
        onChange={(_pagination, filters) => {
          setActiveFiltersObject(filters);
          filterCases(filters, getCaseList, activeFilters, setFiltersCleared);
        }}
        onRow={rowValue => {
          return {
            onClick: () => {
              casesInfo.current = {
                patientName: rowValue[SURGERY_CASE_ATTR.PATIENT_NAME],
                modality: rowValue[SURGERY_CASE_ATTR.MODALITY],
                mrn: rowValue[SURGERY_CASE_ATTR.MRN],
                surgeryDate: rowValue[SURGERY_CASE_ATTR.SURGERY_DATE],
                state: rowValue[SURGERY_CASE_ATTR.CASE_STATUS],
                comment: rowValue[SURGERY_CASE_ATTR.CLASSIFICATION],
                lastModified: rowValue[SURGERY_CASE_ATTR.LAST_MODIFIED]
              };
            }
          };
        }}
      />
      <CloneCaseModal
        isVisible={isCloneCaseModalVisible}
        setIsVisible={setIsCloneCaseModalVisible}
        cloneInfo={cloneInfo}
      />
    </React.Fragment>
  );
};

CaseList.propTypes = {
  history: PropTypes.object,
  getCaseInfo: PropTypes.func,
  clearCaseInfo: PropTypes.func,
  clearPlanningInfo: PropTypes.func,
  getCaseList: PropTypes.func,
  cloneCaseStatus: PropTypes.string,
  clearCloneCaseData: PropTypes.func,
  getPlanningInfo: PropTypes.func,
  caseListData: PropTypes.array,
  caseInfo: PropTypes.object,
  responseData: PropTypes.object,
  planningInfo: PropTypes.object,
  status: PropTypes.string,
  implantsInfo: PropTypes.object,
  loadImplantsInfoStatus: PropTypes.string
};

const mapStateToProps = state => {
  return {
    caseListData: state.caselist.caseList,
    caseInfo: state.cases,
    status: state.caselist.status,
    cloneCaseStatus: state.cloneCase.cloneCaseStatus,
    responseData: state.caselist.responseData,
    planningInfo: state.plannings,
    implantsInfo: state.cases.implantsInfo,
    loadImplantsInfoStatus: state.cases.loadImplantsInfoStatus
  };
};

export default connect(mapStateToProps, {
  getCaseList,
  clearCloneCaseData,
  getCaseInfo,
  clearCaseInfo,
  getPlanningInfo,
  clearPlanningInfo
})(CaseList);
