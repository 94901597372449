import React from "react";
import { BrowserRouter } from "react-router-dom";
import Secured from "./components/auth/Secured";
import "gsi-ui-components/dist/index.css";
import "./App.less";

const App = () => {
  return (
    <BrowserRouter>
      <Secured />
    </BrowserRouter>
  );
};

export default React.memo(App);
